import React from "react";
import Header from "./graphComponents/Header";
import PanelMethods from "./graphComponents/PanelMethods";
import Summary from "./graphComponents/Summary";
import PanelInstance from "./PanelInstance";

const Panel = ({ serverdata, size, slug }) => {
  let data = null;
  if (serverdata.chartType === "VENN DIAGRAM") {
    data = serverdata;
  } else if (serverdata.toggleType === "NONE") {
    data = serverdata;
  }
  // } else if (serverdata.toggleType === "TAB AND TOGGLE AND PANEL") {
  //   data = serverdata.panels;
  // }
  else {
    data = serverdata;
  }

  const panelLayout = (panels) => {
    let i = 0;
    const divs = [];
    while (i < panels.length) {
      if (i % 2 === 0) {
        const panel1 = {
          ...serverdata,
          panel: {
            ...panels[i],
            panelCount: panels.length,
          },
        };
        if (i + 1 < panels.length) {
          const panel2 = {
            ...serverdata,
            panel: {
              ...panels[i + 1],
              panelCount: panels.length,
            },
          };
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={panel1}
                size={size}
                slug={slug}
                tab={panel1.panel?.tab}
                toggle={panel1.panel?.toggle}
              />
              <PanelInstance
                panel={panel2}
                size={size}
                slug={slug}
                tab={panel2.panel?.tab}
                toggle={panel2.panel?.toggle}
              />
            </div>
          );
        } else {
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={panel1}
                size={size}
                slug={slug}
                tab={panel1.panel?.tab}
                toggle={panel1.panel?.toggle}
              />
            </div>
          );
        }
      }
      i++;
    }
    return divs;
  };

  return (
    <>
      {size !== "micro" &&
      serverdata.toggleType !== "TAB AND TOGGLE AND PANEL" ? (
        <>
          {data && data.length ? (
            <Header figureData={data[0]} slug={slug} />
          ) : (
            <Header figureData={data} slug={slug} />
          )}
        </>
      ) : (
        <></>
      )}

      {data && data.panel ? (
        <div className="panel-layout">
          <div className="panel-child">
            {panelLayout(data.panel).map((x) => x)}
          </div>
        </div>
      ) : data ? (
        <PanelInstance panel={data} size={size} slug={slug} />
      ) : (
        <></>
      )}

      {serverdata.toggleType.indexOf("PANEL") > -1 ? (
        <PanelMethods methods={serverdata.methods} />
      ) : (
        <></>
      )}

      {serverdata.toggleType !== "TAB AND TOGGLE AND PANEL" ? (
        data && data.length ? (
          <Summary figureData={data[0]} size={size} />
        ) : (
          <Summary figureData={data} size={size} />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default Panel;

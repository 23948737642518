import React from "react";

const Introduction_2021 = () => {
  return (
    <>
      <h1>Introduction</h1>
      <section>
        <p>
          The 2021 United States Renal Data System Annual Data Report (ADR) is
          again presented in an interactive format, which we introduced with the
          2020 ADR. However, the content has been improved, restructured, and
          expanded. Building on the COVID-19 Supplement (coronavirus disease
          2019) introduced in the 2020 ADR, we continue the ADR’s critical
          surveillance mission by including an updated COVID-19 Supplement that
          presents many outcomes through the end of 2020 and some through the
          second quarter of 2021. We utilize quarterly claims data from payment
          year 2020 to describe COVID testing and cases, incidence of
          COVID-related AKI, utilization of therapeutic interventions (e.g.,
          hospitalization, ICU care, mechanical ventilation), and post-discharge
          outcomes in COVID survivors.
        </p>
        <p>
          The 2021 ADR includes a new chapter focused on racial and ethnic
          disparities in healthcare delivery and outcomes in the CKD and ESRD
          populations. This chapter does not replace material presented
          elsewhere in the ADR reporting incidence, prevalence, and outcome data
          by race and ethnicity. Rather, this chapter examines possible
          contributors to the observed disparities with an emphasis on social
          determinants of health and access to care. This chapter examines
          disparities in the context of CKD, AKI, dialysis, and kidney
          transplantation, and because it spans the continuum of kidney disease,
          we present it as a supplement rather than as part of the CKD or ESRD
          volume.
        </p>
        <p>
          We have also included a new chapter on home dialysis. Many other
          chapters in the ESRD volume present outcomes by modality, but this
          chapter delves into greater detail and presents information not
          previously captured in the ADR. For example, we examine
          characteristics of patients performing home dialysis, the way in which
          home dialysis is delivered, complications experienced by patients
          performing home dialysis, and switches between home modalities and
          in-facility hemodialysis.
        </p>
        <p>
          We have eliminated the chapters dedicated to cardiovascular disease,
          dispersing much of their content into the relevant remaining chapters.
          For example, data on cardiovascular events is presented in the
          hospitalization chapters, and medication utilization among patients
          with cardiovascular conditions is reported in the prescription drug
          chapters.
        </p>
        <section>
          This year’s ADR also includes new or reintroduced sources of data:
          <section style={{ marginTop: "1rem" }}>
            <ol>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  Optum<sup>®</sup> de-identified Clinformatics<sup>®</sup> Data
                  Mart database:
                </span>{" "}
                Optum<sup>®</sup> de-identified Clinformatics
                <sup>®</sup> Data Mart database, reintroduced in the 2021 ADR,
                provides paid medical and prescription claims and enrollment
                information for participants in commercial insurance plans and
                Medicare Advantage plans of a large U.S. managed-care health
                insurance company. Included plan members are enrolled in both a
                medical and a prescription plan, and the sample represents all
                areas of the country. Optum data from 2019 are used in
                estimating CKD prevalence, assessing the burden of illness and
                hospitalizations in CKD patients in the CKD volume.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  CMS Medicaid T-MSIS Analytic Files:
                </span>{" "}
                The CMS Transformed Medicaid Statistical Information System
                (T-MSIS) analytical file contains information on Medicaid
                beneficiaries and CHIP program enrollees. The information
                includes enrollment records, service utilization, claims, and
                management care. New in this year’s ADR, the USRDS requested the
                T-MSIS files for beneficiaries with advanced CKD and AKI
                diagnosis/procedure codes in 2018-2019. These data are used in
                various chapters in the CKD volume.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  The ESRD Quality Reporting System (EQRS) data:
                </span>{" "}
                The ESRD Quality Reporting System (EQRS)replaces and updates the
                legacy CROWNWeb/REMIS. EQRS is a national registry for ESRD
                patients in the U.S. EQRS captures clinical and administrative
                data for all ESRD patients, including the CMS-2728 Medical
                Evidence Report, CMS-2746 ESRD Death Notification, ESRD
                treatment modalities, and inpatient stays. Recently acquired
                EQRS data include information through the second quarter of
                2021. These data are used to track COVID-19 among ESRD patients
                and are presented in the COVID-19 supplement of this year’s ADR.
              </li>
              <li>
                <span style={{ textDecoration: "underline" }}>
                  The Medicare Provider Analysis and Review (MedPAR) data:
                </span>{" "}
                New in this year’s ADR, MedPAR data contains inpatient and
                Skilled Nursing Facility (SNF) stay records for Medicare
                beneficiaries from the National Claims History (NCH). We use
                MedPAR data to estimate rates of all cause and cause-specific
                hospitalization among adult patients receiving dialysis who were
                covered by Medicare Advantage plans in 2019 and to compare these
                rates with those among the Medicare FFS population.
              </li>
            </ol>
          </section>
        </section>
        <p>
          The need to process and finalize medical claims introduces an
          unavoidable lag between enrollees’ receipt of clinical care and
          availability of claims data. Thus, 2019 is the most recent year for
          which data are available for most analyses in the 2021 ADR. We note
          that data for 2019 – and trends over the decade leading up to 2019 –
          are likely to form an important “baseline” upon which to examine
          substantial clinical and policy changes that began in 2020. Most
          notably, preliminary data have already shown that the COVID-19
          pandemic led to substantial mortality in the ESRD population
          (Weinhandl, Wetmore, et al., 2021) and a simultaneous decline in rates
          of incident ESRD (Wetmore et al., 2021) (see the COVID-19 Supplement
          for updated data). Together, these events brought about the first
          substantial shrinkage in the ESRD population (Weinhandl, Gilbertson,
          et al., 2021). Initiation of in-facility hemodialysis declined to a
          greater extent than initiation of peritoneal dialysis, leading to an
          increase in the percentage of new ESRD patients starting peritoneal
          dialysis that was not accompanied by an increase in new peritoneal
          dialysis starts (Wetmore et al., 2021).
        </p>
        <p>
          On July 10, 2019, the White House and the Department of Health and
          Human Services unveiled the Advancing American Kidney Health (AAKH)
          initiative with the objectives of preventing kidney failure and
          improving access to person-centered care and transplantation among
          patients with ESRD (U.S. Department of Health and Human Services,
          2019). The president’s Executive Order announcing the initiative set
          forth ambitious targets for increasing the use of home dialysis
          therapies and kidney transplantation, directed Medicare to develop and
          test payment models to create incentives aligned with these
          objectives, and introduced revisions (which were finalized in December
          2020) to the performance outcome measures for organ procurement
          organizations. Although the Kidney Care Choices models were delayed
          until January 2022, ESRD Treatment Choices (ETC) launched in January
          2021. Although CMS and its contractors will perform formal monitoring
          of the impact of these programs, the USRDS will also present changes
          in care of patients with CKD and ESRD in upcoming ADRs as the
          nephrology community enters the post-COVID, AAKH era. Data from this
          year’s ADR – and from 2019 in particular – will serve as a reference
          point for assessments of the impact of these policy initiatives.
        </p>
        <p>
          Although 2020 truly marks the end of the decade of the 2010s, it may
          be appropriate to summarize the progress in the last decade ending
          with 2019 given the upheavals that will become apparent in 2020 and
          the changes in provision and reimbursement of care of dialysis
          patients that occurred in the last decade. Providers have been
          preparing for or operating under an evolving bundled payment system
          and the Quality Improvement Program (QIP) that came with it for most
          of the decade (since 2011). As providers adapted to these large
          changes in reimbursement and searched for financial efficiencies,
          there have been a number of changes in dialysis patient care that have
          had clear financial consequences. Examples include the transition from
          epoetin alfa to pegylated epoetin beta as the leading agent for the
          treatment of anemia; the steady growth of iron-based phosphate binders
          despite the concurrent arrival of inexpensive, generic sevelamer;
          rapid uptake of etelcalcetide while transitional add-on payments to
          Medicare reimbursement for dialysis were available; and introduction
          of oral calcitriol as a substitute for intravenous vitamin D receptor
          activators. To date, the effects of these changes on patient outcomes
          are not clear. The supply and cost of labor (including nurses and
          technicians) may be the next big driver of evolution of care delivery
          in the coming decade.
        </p>
        <section>
          Based on the data in this year’s ADR, the last decade was marked by
          substantial improvement in several important metrics and clinical
          outcomes among individuals with CKD and ESRD. Among others, these
          include:
          <section style={{ marginTop: "1rem" }}>
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>CKD outcomes</span>
                <ul>
                  <li>
                    A 22% reduction in adjusted all-cause mortality among
                    Medicare fee-for-service (FFS) beneficiaries ≥66 years of
                    age with CKD that was more than twice as large as the
                    reduction in mortality observed among beneficiaries without
                    CKD.
                  </li>
                  <li>
                    A 29% reduction in the adjusted rate of all-cause
                    hospitalization among Medicare FFS beneficiaries aged 66
                    years or older with CKD.
                  </li>
                  <li>
                    A 14% reduction in readmission after hospital discharge
                    among older Medicare FFS beneficiaries with CKD.
                  </li>
                </ul>
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>ESRD outcomes</span>
                <ul>
                  <li>
                    An increase in the percentage of older Medicare
                    beneficiaries with prior CKD initiating dialysis outside of
                    the hospital since 2014, reaching almost 60%.
                  </li>
                  <li>
                    A 7% reduction in adjusted ESRD incidence to 373 per million
                    population in 2019.
                  </li>
                  <li>
                    A 25% reduction in the percentage of patients initiating
                    dialysis with eGFR ≥15 ml/min/1.73m<sup>2</sup>.
                  </li>
                  <li>
                    An 85% increase in the percentage of incident dialysis
                    patients starting home dialysis, and a 73% increase in the
                    percentage of patients performing home dialysis one year
                    after dialysis initiation.
                  </li>
                  <li>
                    A 61% reduction in the rate of hospitalization for
                    peritonitis and a 36% reduction in the rate of
                    hospitalization for catheter complications among patients
                    performing peritoneal dialysis.
                  </li>
                  <li>
                    A 13% reduction in adjusted rate of all-cause
                    hospitalization among prevalent hemodialysis patients, 14%
                    reduction among prevalent peritoneal dialysis patients, and
                    11% reduction among kidney transplant recipients. Reductions
                    in the rate of all-cause hospitalization in the first year
                    after dialysis initiation were even larger at 18% for
                    patients treated with hemodialysis and 20% among those
                    performing peritoneal dialysis.
                  </li>
                  <li>
                    A 17.5% reduction in adjusted all-cause mortality among
                    patients on hemodialysis and a 21% reduction among patients
                    performing peritoneal dialysis.
                  </li>
                  <li>
                    Increase in the rate of kidney transplantation among
                    patients with ESRD that began in the second half of the
                    decade – a reversal that followed a period of decline during
                    the first half of the decade.
                  </li>
                </ul>
              </li>
            </ul>
          </section>
        </section>
        <p>
          These developments should be celebrated. However, progress on several
          of these metrics slowed or stalled during the second half of the
          decade. This was true for rates of hospitalization among patients with
          CKD, receiving maintenance dialysis, and with a functioning kidney
          transplant. The downward trajectory of mortality among patients
          receiving maintenance dialysis also flattened after about 2014, and
          there has been no further reduction in early dialysis initiation in
          the last few years. Furthermore, important metrics, such as rates of
          acute kidney injury among patients with CKD and rates of catheter use
          among incident hemodialysis patients, have not improved.
        </p>
        <p>
          The extensive characterization of changes over the last decade and of
          the state of healthcare utilization and outcomes in the CKD and ESRD
          populations in 2019 presented in this year’s ADR will form the
          backdrop upon which the effects of the COVID-19 pandemic and new
          payment models can be reported in subsequent ADRs.
        </p>
        <h2>Notes about race and ethnicity</h2>
        <p>
          In the 2021 ADR, we present data in the CKD and ESRD volumes using
          combined categories defined by race and ethnicity. Hispanic
          individuals are considered as a separate category (that includes
          Hispanics of all races). Other race groups include non-Hispanic
          individuals within those groups (e.g., non-Hispanic White,
          non-Hispanic Black). This strategy is consistent with race and
          ethnicity categories available within Medicare claims data but
          represents a change from how race and ethnicity have been previously
          presented in the ESRD volume. This change serves to better align race
          and ethnicity categories in the CKD and ESRD volumes. In addition,
          this strategy facilitates direct comparisons among Hispanic and
          non-Hispanic individuals in different race groups that were not
          possible previously for those with ESRD. In other words, rather than
          simply displaying data for Hispanic individuals alongside data for a
          multiracial group of non-Hispanic individuals, figures display
          Hispanic, non-Hispanic White, non-Hispanic Black, and often other race
          groups as separate categories. However, to avoid using abbreviations
          in figure tabs and to streamline descriptions of findings, we do not
          include “non-Hispanic” in the names of each race category. We present
          data for Hispanic individuals and individuals of smaller
          race/ethnicity categories whenever possible, but in many instances,
          stable estimates of outcomes cannot be generated for smaller
          race/ethnicity categories. This occurs most often among children and
          for some relatively rare outcomes among patients with CKD. In
          addition, we do not stratify by race/ethnicity in analyses using
          Medicaid data because race/ethnicity information is missing entirely
          in many states and is missing for the majority of beneficiaries in
          many other states. Therefore, it is not possible to examine
          race/ethnicity groups that are representative of the U.S. population
          using Medicaid data.
        </p>
        <p>
          In 2017, the USRDS reported incidence and prevalence of ESRD for
          individuals in the Native Hawaiian or Pacific Islander (NHPI) race
          group separately (from Asians) for the first time (Saran et al.,
          2018). The extraordinarily high rates in this population caused
          concern (Naʻai &amp; Raphael, 2019) and spurred further evaluation
          (Xiang et al., 2020, Johansen et al., 2022), which has identified
          considerable uncertainty about the true rates in this population.
          Differences in availability of information about multi-racial
          individuals in the USRDS and the U.S. Census are a major source of
          this uncertainty. Over half of individuals who identify as NHPI in the
          U.S. Census also selected at least one other race category (Centers
          for Disease Control and Prevention, 2019). Thus, rate calculations
          that include only individuals designated solely as NHPI in the U.S.
          Census in the denominator will overestimate the incidence and
          prevalence of ESRD in this population.
        </p>
        <p>
          However, including all individuals who designate NHPI in the
          denominator (including multiracial individuals) will almost certainly
          underestimate the prevalence because the USRDS (effectively)
          designates individuals as belonging in only one race category.
          Although the Medical Evidence Report (form CMS 2728) allows
          individuals to choose more than one race, fewer than 1% of individuals
          have more than one race category designated in the USRDS, and no one
          who started dialysis after 2012 has more than one category. (This
          situation is the result of data entry into and retrieval and
          processing of data from CROWNWeb, the ultimate source of race data for
          the ESRD population in the USRDS.) Thus, the single race category in
          the USRDS is unlikely to be capturing all NHPI who self-report more
          than one race. Because there is no way to ascertain the true rates of
          ESRD in the NHPI population at present, and because the possible range
          is extremely wide, we do not report incidence and prevalence of ESRD
          for this race group in the text of the 2020 ADR. However, we do report
          outcomes among this race group when sample size allows stable
          estimates.
        </p>
        <p>
          We calculated incidence and prevalence rates for other race groups
          using all individuals in the U.S. Census who designated that race
          (including multi-racial individuals) in the denominator for each
          group. This strategy results in a small decrease in rates because few
          individuals in these race groups designate more than one race. The
          problem of incomplete data on race in the USRDS is one that needs
          further exploration as the number of multiracial individuals in the
          U.S has increased over time. Specifically, the 2020 Census reports a
          276% increase in the Multiracial population of the United States since
          2010 (Jones et al., 2021).{" "}
        </p>
      </section>
      <h3 className="section-references">References</h3>
      <ul className="section-references-list">
        <li>
          Centers for Disease Control and Prevention. (2019). U.S. Census
          populations with bridged race categories. Retrieved August 4, 2020
          from{" "}
          <a
            href="https://www.cdc.gov/nchs/nvss/bridged_race.htm"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.cdc.gov/nchs/nvss/bridged_race.htm
          </a>
        </li>
        <li>
          Johansen KL, Wetmore JB, Peng Y, Liu J, Weinhandl ED, Gilbertson DT.
          Variation in incidence of ESKD among individuals of Native
          Hawaiian/Pacific Islander race based on data from the United States
          Renal Data System. Am J Kidney Dis, 2022, in press.
        </li>
        <li>
          Jones, N., Marks, R., Ramirez, R., &amp; Rios-Vargas, M. (2021).{" "}
          <span style={{ fontStyle: "italic" }}>
            2020 Census illuminates racial and ethnic composition of the
            country.
          </span>{" "}
          United States Census Bureau. Retrieved November 19, 2021 from{" "}
          <a
            href="https://www.census.gov/library/stories/2021/08/improved-race-ethnicity-measures-reveal-united-states-population-much-more-multiracial.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.census.gov/library/stories/2021/08/improved-race-ethnicity-measures-reveal-united-states-population-much-more-multiracial.html
          </a>
        </li>
        <li>
          Naʻai, D., &amp; Raphael, K. L. (2019, Nov 7). CKD in Native Hawaiians
          and Pacific Islanders: Trouble in Paradise. Clin J Am Soc Nephrol,
          14(11), 1661-1663.{" "}
          <a
            href="https://doi.org/10.2215/cjn.03260319"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.2215/cjn.03260319
          </a>
        </li>
        <li>
          Saran, R., Robinson, B., Abbott, K. C., Agodoa, L. Y. C., Bhave, N.,
          Bragg-Gresham, J., Balkrishnan, R., Dietrich, X., Eckard, A., Eggers,
          P. W., Gaipov, A., Gillen, D., Gipson, D., Hailpern, S. M., Hall, Y.
          N., Han, Y., He, K., Herman, W., Heung, M., Hirth, R. A., Hutton, D.,
          Jacobsen, S. J., Jin, Y., Kalantar-Zadeh, K., Kapke, A., Kovesdy, C.
          P., Lavallee, D., Leslie, J., McCullough, K., Modi, Z., Molnar, M. Z.,
          Montez-Rath, M., Moradi, H., Morgenstern, H., Mukhopadhyay, P.,
          Nallamothu, B., Nguyen, D. V., Norris, K. C., O'Hare, A. M., Obi, Y.,
          Park, C., Pearson, J., Pisoni, R., Potukuchi, P. K., Rao, P., Repeck,
          K., Rhee, C. M., Schrager, J., Schaubel, D. E., Selewski, D. T., Shaw,
          S. F., Shi, J. M., Shieu, M., Sim, J. J., Soohoo, M., Steffick, D.,
          Streja, E., Sumida, K., Tamura, M. K., Tilea, A., Tong, L., Wang, D.,
          Wang, M., Woodside, K. J., Xin, X., Yin, M., You, A. S., Zhou, H.,
          &amp; Shahinian, V. (2018, Mar). US Renal Data System 2017 Annual Data
          Report: Epidemiology of Kidney Disease in the United States. Am J
          Kidney Dis, 71(3 Suppl 1), A7.{" "}
          <a
            href="https://doi.org/10.1053/j.ajkd.2018.01.002"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1053/j.ajkd.2018.01.002
          </a>
        </li>
        <li>
          U.S. Department of Health and Human Services. (2019). Advancing
          American Kidney Health.{" "}
          <a
            href="https://aspe.hhs.gov/system/files/pdf/262046/AdvancingAmericanKidneyHealth.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://aspe.hhs.gov/system/files/pdf/262046/AdvancingAmericanKidneyHealth.pdf
          </a>
        </li>
        <li>
          Weinhandl, E. D., Gilbertson, D. T., Wetmore, J. B., &amp; Johansen,
          K. L. (2021, Oct). COVID-19-Associated Decline in the Size of the
          End-Stage Kidney Disease Population in the United States. Kidney Int
          Rep, 6(10), 2698-2701.{" "}
          <a
            href="https://doi.org/10.1016/j.ekir.2021.07.019"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1016/j.ekir.2021.07.019
          </a>
        </li>
        <li>
          Weinhandl, E. D., Wetmore, J. B., Peng, Y., Liu, J., Gilbertson, D.
          T., &amp; Johansen, K. L. (2021, Jun 1). Initial Effects of COVID-19
          on Patients with ESKD. J Am Soc Nephrol, 32(6), 1444-1453.{" "}
          <a
            href="https://doi.org/10.1681/asn.2021010009"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1681/asn.2021010009
          </a>
        </li>
        <li>
          Wetmore, J. B., Johansen, K. L., Liu, J., Peng, Y., Gilbertson, D. T.,
          &amp; Weinhandl, E. D. (2021, Nov). Changes in Treatment of Patients
          with Incident ESKD during the Novel Coronavirus Disease 2019 Pandemic.
          J Am Soc Nephrol, 32(11), 2948-2957.{" "}
          <a
            href="https://doi.org/10.1681/asn.2021040579"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1681/asn.2021040579
          </a>
        </li>
        <li>
          Xiang, J., Morgenstern, H., Li, Y., Steffick, D., Bragg-Gresham, J.,
          Panapasa, S., Raphael, K. L., Robinson, B. M., Herman, W. H., &amp;
          Saran, R. (2020, May 5). Incidence of ESKD Among Native Hawaiians and
          Pacific Islanders Living in the 50 US States and Pacific Island
          Territories. Am J Kidney Dis.{" "}
          <a
            href="https://doi.org/10.1053/j.ajkd.2020.01.008"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://doi.org/10.1053/j.ajkd.2020.01.008
          </a>
        </li>
      </ul>
    </>
  );
};

export default Introduction_2021;

import { isArray, mergeWith } from "lodash";

export const nonNullMerge = (src, ...args) => {
  return mergeWith(src, ...args, (a, b) => (b === null ? a : undefined));
};

export const arrayReplaceMerge = (src, ...args) => {
  return mergeWith(src, ...args, (a, b, key, obj) => {
    const rr = isArray(a) && isArray(b) ? [...b] : undefined;
    return rr;
  });
};

export const defaultMerge = (src, ...args) => {
  return mergeWith(src, ...args, (a, b) => {
    if (b === null) {
      return a;
    } else if (isArray(a) && isArray(b)) {
      return b;
    }
  });
};

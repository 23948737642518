import React from 'react'
import DOMPurify from 'dompurify';

const Summary = ({ figureData, size }) => {
    return (size !== 'micro' && size !== 'mini') ?
        <>
            <section dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(figureData.summary, { ADD_ATTR: ['target'] }) }}>
            </section>
        </>
        : <></>
}

export default Summary
const highchartsVennExportFix = function (data) {
    return function ({ dataRows, ...evt }) {
        evt.preventDefault()
        if (data.chartType.indexOf('VENN') > -1 && this.series.length) {
            const firstSeries = this.series[0].data

            for (let i = 0; i < dataRows.length; i++) {
                // header
                let seriesIdx = i - 1
                if (i === 0) {
                    continue;
                }

                // weird xls export fix
                if (dataRows.length - firstSeries.length === 2) {
                    if (i === 1) {
                        continue;
                    }
                    seriesIdx = i - 2
                }

                let exportRow = dataRows[i]
                let seriesRow = firstSeries[seriesIdx]

                if (seriesRow.labelText === 'None') {
                    exportRow[0] = 'None'
                    exportRow[1] = seriesRow.noneValue
                } else {
                    if (seriesRow.sets.length > 1 && seriesRow.sets.some(x => x === 'None')) {
                        exportRow[0] = null
                        exportRow[1] = null
                    } else {
                        exportRow[0] = seriesRow.labelText
                    }
                }
            }
        }

    }
}

export default highchartsVennExportFix
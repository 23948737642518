import React from "react";
import { useParams } from "react-router";
import Introduction2021 from "./Introduction_2021";
import Introduction2022 from "./Introduction_2022";
import Introduction2023 from "./Introduction_2023";
import IntroductionText from "./Introduction_Text";


const Introduction = () => {
  const { year } = useParams();
  if (year === "2021") {
    return <Introduction2021 />;
  } else if (year === "2022") {
    return <Introduction2022 />;  
  } else if (year === "2023") {
    return <Introduction2023 />;      
  } else {
    return <IntroductionText />;
  }
};

export default Introduction;

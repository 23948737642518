import { Table } from "react-bootstrap";
import config from "../../../config/config";
import React from "react";
import "../HealthyPeople.scss";

const HealthyPeople2030_2023 = () => {
  return (
    <>
      <h1>
        Healthy People 2030 CKD Objectives
        <a
          style={{
            fontSize: ".5em",
            textDecoration: "underline",
            float: "right",
          }}
          href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD_All`}
        >
          Download All Objectives
        </a>
      </h1>
      <section>
        <Table responsive className="hp-table">
          <thead>
            <tr>
              <td>Objective</td>
              <td>Indicator</td>
              <td>Target</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="3">Chronic Kidney Disease — General</td>
            </tr>
            <tr>
              <td>CKD-03</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD03`}
                >
                  Increase the proportion of people on Medicare who get
                  follow-up care 3 months after kidney injury
                </a>
              </td>
              <td>16.4%</td>
            </tr>
            <tr>
              <td>CKD-04</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD04`}
                >
                  Increase the proportion of people on Medicare with chronic
                  kidney disease who get recommended tests
                </a>
              </td>
              <td>49.5%^</td>
            </tr>
            <tr>
              <td>CKD-07</td>
              <td>
                {" "}
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD07`}
                >
                  Reduce the rate of new cases of end-stage kidney disease
                </a>
              </td>
              <td>290.0 PMP</td>
            </tr>
            <tr>
              <td>CKD-08</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD08`}
                >
                  Reduce the proportion of adult dialysis patients who rely on
                  catheters for dialysis
                </a>
              </td>
              <td>9.7%^</td>
            </tr>
            <tr>
              <td>CKD-09</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD09`}
                >
                  Increase the proportion of people who get a kidney transplant
                  within 3 years of end-stage kidney disease treatment
                </a>
              </td>
              <td>19.7%</td>
            </tr>
            <tr>
              <td>CKD-10</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_CKD10`}
                >
                  Reduce the death rate for people on dialysis
                </a>
              </td>
              <td>117.2 per 1,000 PY</td>
            </tr>
            <tr>
              <td colSpan="3">Diabetes</td>
            </tr>
            <tr>
              <td>D-05</td>
              <td>
                <a
                  href={`${config.baseUri}/healthyPeople2030?year=2023&hpTable=HP2030_D05`}
                >
                  Increase the proportion of adults with diabetes who get a
                  yearly urinary albumin test
                </a>
              </td>
              <td>66.4%</td>
            </tr>
          </tbody>
        </Table>
        <p>
          Data Source:{" "}
          <a
            href="https://health.gov/healthypeople/objectives-and-data/browse-objectives/chronic-kidney-disease"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://health.gov/healthypeople/objectives-and-data/browse-objectives/chronic-kidney-disease
          </a>
          .{" "}
        </p>
        <p>
          Abbreviations: CKD, chronic kidney disease; HP2030, Healthy People
          2030; PMP, per million population; PY, patient-years
        </p>
        <p>
          ^Target has been revised in 2023
        </p>
      </section>
    </>
  );
};

export default HealthyPeople2030_2023;

import React from "react";
import { Waypoint } from "react-waypoint";
import DOMPurify from "dompurify";
import Figure from "../diagrams/graphs/Figure";

import "./SectionWaypoint.scss";
import { useApolloClient } from "@apollo/client";
import { GET_SECTIONS, GET_CURRENT_SECTION } from "../../../queries/sections";
import config from "../../../config/config";

export default function SectionWaypoint({ section, first }) {
  const client = useApolloClient();

  const onEnter = (section) => () => {
    client.writeQuery({
      query: GET_CURRENT_SECTION,
      data: { currentSection: section },
    });
    const { sectionsVisible } = client.readQuery({ query: GET_SECTIONS });
    const currentSections = new Array(...sectionsVisible);
    currentSections.push(section);
    client.writeQuery({
      query: GET_SECTIONS,
      data: { sectionsVisible: currentSections },
    });
  };

  const onLeave = (section) => () => {
    const { sectionsVisible } = client.readQuery({ query: GET_SECTIONS });
    const currentSections = sectionsVisible.filter((sec) => sec !== section);
    client.writeQuery({
      query: GET_SECTIONS,
      data: { sectionsVisible: currentSections },
    });
  };

  return (
    <div key={section.slug} className={`section-heading`}>
      {section.figure && section.figure.figureNumber ? (
        <></>
      ) : (
        <h2
          className={`reg-title ${first ? `reg-title-first` : ``}`}
          id={section.slug}
        >
          {section.title}
        </h2>
      )}
      <Waypoint
        onEnter={onEnter(section.title)}
        onLeave={onLeave(section.title)}
        fireOnRapidScroll={false}
      >
        <div
          id={`${section.slug}-wrapper`}
          className={`${
            section.figure && section.figure.figureNumber
              ? "section-wrapper"
              : "dont-break"
          }`}
        >
          <article>
            {section.figure && section.figure.figureNumber ? (
              <section>
                <div>
                  <Figure
                    figureNumber={section.figure.figureNumber}
                    chapterId={section.chapterId}
                    figureType={section.figure.toggleType}
                    slug={section.slug}
                    chartType={section.figure.chartType}
                  />
                </div>
              </section>
            ) : (
              <></>
            )}
            {section.sectionText && section.sectionText.length ? (
              section.sectionText.map((x) => (
                <section
                  className="text-section"
                  key={x.id}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      // eslint-disable-next-line
                      x.text.replace("${baseUri}", config.baseUri),
                      {
                        ADD_ATTR: ["target"],
                      }
                    ),
                  }}
                ></section>
              ))
            ) : (
              <></>
            )}
          </article>
        </div>
      </Waypoint>
    </div>
  );
}

import React from 'react'

const Tab = ({ currentTab, tabValues, tabDataSet }) => {
    return (
        <div className="graph-tab-section">
            { tabValues ?
                tabValues.map(tab =>
                    <div role="button" key={tab}
                        className={`graph-section-tab ${tab === currentTab ? 'active' : ''}`}
                        onClick={() => tabDataSet(tab)}>{tab}</div>
                )
                : <></>}
        </div>
    )
}

export default Tab
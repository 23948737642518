import { toolTipFormatter } from "./numberFormatters";

export function removeNoneCircles(data, { tab, toggle }, isExporting) {
  return function () {
    if (isExporting) {
      const circles =
        window.document.getElementsByClassName("None-graph-circle");
      for (let i = 0; i < circles.length; i++) {
        circles[i].setAttribute("display", "none");
      }
      const delCircles =
        window.document.getElementsByClassName("delete-my-circle");
      for (let i = 0; i < delCircles.length; i++) {
        delCircles[i].setAttribute("display", "none");
      }
    }

    if (
      data.chartType.indexOf("VENN") > -1 &&
      this.series.length &&
      this.series[0].data.filter((x) => x.labelText === "None").length
    ) {
      const noneDataSet = this.series[0].data.filter(
        (x) => x.labelText === "None"
      )[0];
      let figId = data.figureNumber.replace(/\W/g, "");
      if (noneDataSet.panel) {
        figId += noneDataSet.panel.replace(/\W/g, "");
      }

      const noneValue = noneDataSet.noneValue;
      const noneVal = toolTipFormatter(
        data.dataLabelDecimalPlaces,
        "VENN"
      ).call({
        y: noneValue,
        series: {
          name: "None",
        },
        point: {
          value: noneValue,
          noneValue: noneValue,
          options: {
            labelText: "None",
          },
        },
        key: "None",
      });

      if (!isExporting) {
        reCenterVenn(this);
      }

      const textX = this.chartWidth / 2 - 25;
      const { fig, exp } = noneAdjust(data.figureNumber);
      let textY = this.chartHeight - 45 + fig;
      if (isExporting) {
        textY += exp;
      }
      const txt = this.renderer
        .text(noneVal, textX, textY, true)
        .attr({
          fill: "black",
          stroke: "black",
          "stroke-width": 1,
          class: "a-none-circle-" + figId,
        })
        .css({
          fontSize: "14px",
        });
      txt.add();

      processSpecialLabels();
    }
  };
}

const processSpecialLabels = () => {
  // adjust figure 4.6 labels
  const classNames = [
    "LipidloweringagentsCADCKDn47517",
    "LipidloweringagentsAMINoCKDn19092",
    "LipidloweringagentsAMICKDn12002",
    "WarfarinDOACsNVAFNoCKDn77624",
    "BetablockersAMIAllDialysisn29539",
    "LipidloweringagentsAMITransplantn3634",
    "BetablockersHFTransplantn10234",
    "LipidloweringagentsPADTransplantn12326",
    "DMMedicareAdvantageCKD",
    "DMPercentageofcoststotal30855B",
  ];
  for (let i = 0; i < classNames.length; i++) {
    const elems = document.getElementsByClassName(classNames[i]);
    for (let j = 0; j < elems.length; j++) {
      const txts = elems[j].getElementsByTagName("text");
      for (let k = 0; k < txts.length; k++) {
        if (
          classNames[i] === "WarfarinDOACsNVAFNoCKDn77624" ||
          classNames[i] === "LipidloweringagentsAMITransplantn3634"
        ) {
          txts[k].setAttribute("transform", "translate(82, 0)");
        } else if (
          classNames[i] === "BetablockersAMIAllDialysisn29539" ||
          classNames[i] === "BetablockersHFTransplantn10234" ||
          classNames[i] === "LipidloweringagentsPADTransplantn12326"
        ) {
          txts[k].setAttribute("transform", "translate(100, 0)");
        } else if (
          classNames[i] === "DMPercentageofcoststotal30855B" ||
          classNames[i] === "DMMedicareAdvantageCKD"
        ) {
          txts[k].setAttribute("transform", "translate(45, 50)");
        } else {
          txts[k].setAttribute("transform", "translate(85, 90)");
        }
      }
    }
  }
};

const noneAdjust = (figNum) => {
  switch (figNum) {
    case "Figure 1.6":
      return { fig: -30, exp: 30 };
    case "Figure 4.6":
      return { fig: 42, exp: -15 };
    case "Figure 6.1":
      return { fig: 35, exp: -35 };
    case "Figure 8.4":
      return { fig: 40, exp: -10 };
    default:
      return { fig: 0, exp: 0 };
  }
};

const reCenterVenn = (chart) => {
  var figChart = document.getElementsByClassName("venn-adjust");
  for (let i = 0; i < figChart.length; i++) {
    let figChartArea = figChart[i].getElementsByClassName(
      "highcharts-series-group"
    )[0];
    let figChartLabels = figChart[i].getElementsByClassName(
      "highcharts-data-labels"
    )[0];
    let figToolTips =
      figChart[i].getElementsByClassName("highcharts-tooltip")[0];
    let { x, width } = figChartArea.getBBox();
    const cWidth = chart.chartWidth;

    let sign = "-";

    if (x + width / 2 < cWidth / 2) {
      sign = "";
    }

    let newX = x + width / 2 - cWidth / 2;
    figChartArea.setAttribute("transform", `translate(${sign + newX}, 0)`);

    let figLabels = figChartLabels.getElementsByTagName("g");
    for (let j = 0; j < figLabels.length; j++) {
      let txtLbl = figLabels[j].getElementsByTagName("text");
      for (let k = 0; k < txtLbl.length; k++) {
        txtLbl[k].setAttribute("transform", `translate(${sign + newX}, 0)`);
      }
    }

    if (!figToolTips) {
      const config = { childList: true, subtree: true };

      const root = figChart[i].getElementsByClassName("highcharts-root")[0];

      const cb = function (mutList, obs) {
        if (figChart[i]) {
          figChartArea = figChart[i].getElementsByClassName(
            "highcharts-series-group"
          )[0];
          const bbBoxArea = figChartArea.getBBox();
          x = bbBoxArea.x;
          width = bbBoxArea.width;
          if (x + width / 2 < cWidth / 2) {
            sign = "";
          } else {
            sign = "-";
          }
          newX = x + width / 2 - cWidth / 2;

          if (figChart[i]) {
            figToolTips =
              figChart[i].getElementsByClassName("highcharts-tooltip")[0];
            if (figToolTips) {
              let ttBoxes = figToolTips.getElementsByTagName("path");
              for (let j = 0; j < ttBoxes.length; j++) {
                ttBoxes[j].setAttribute(
                  "transform",
                  `translate(${sign + newX}, 0)`
                );
              }
              let txt = figToolTips.getElementsByTagName("text");
              for (let j = 0; j < txt.length; j++) {
                txt[j].setAttribute(
                  "transform",
                  `translate(${sign + newX}, 0)`
                );
              }
            }
          } else {
            obs.disconnect();
          }
        }
      };

      const obs = new MutationObserver(cb);
      obs.observe(root, config);
    } else {
      figChartArea = figChart[i].getElementsByClassName(
        "highcharts-series-group"
      )[0];
      const bbBoxArea = figChartArea.getBBox();
      x = bbBoxArea.x;
      width = bbBoxArea.width;
      if (x + width / 2 < cWidth / 2) {
        sign = "";
      } else {
        sign = "-";
      }
      newX = x + width / 2 - cWidth / 2;

      let ttBoxes = figToolTips.getElementsByTagName("path");
      for (let j = 0; j < ttBoxes.length; j++) {
        ttBoxes[j].setAttribute("transform", `translate(${sign + newX}, 0)`);
      }
      let txt = figToolTips.getElementsByTagName("text");
      for (let j = 0; j < txt.length; j++) {
        txt[j].setAttribute("transform", `translate(${sign + newX}, 0)`);
      }
    }
  }
};

import React from 'react';
import ChapterSummary from './ChapterSummary';

import './ChapterContent.scss';

const ChapterContent = ({ outline }) => {
    let view = <ChapterSummary outline={outline} />
    return (
        <>
            {view}
        </>
    )
}

export default ChapterContent
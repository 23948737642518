import React from 'react'
import { replaceToggle, replaceTab } from '../../../../../utils/dynamicHeader'

export const replaceHdr = (title, currentTab, currentToggle) => {
    let replacement = replaceToggle(currentToggle, title)
    replacement = replaceTab(currentTab, replacement)
    return replacement
}


const Header = ({ figureData, currentToggle, slug, currentTab }) => {
    return (
        <>
            <div className="graph-header" id={slug} style={{ paddingTop: '2em' }}>
                <h2>
                    <div className="graph-header-figureNumber">{figureData.figureNumber}</div>
                    &nbsp;
                    <div className="graph-header-title">{replaceHdr(figureData.title, currentTab, currentToggle)}</div></h2>
            </div>
        </>
    )
}

export default Header
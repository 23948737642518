import React from "react";
import { Waypoint } from "react-waypoint";
import { useApolloClient } from "@apollo/client";
import { useRouteMatch } from "react-router-dom";
import poster from "./AboutTheNewADRPoster.png";
import { GET_CURRENT_SECTION, GET_SECTIONS } from "../../queries/sections";

const AboutTheNewADR = () => {
  const client = useApolloClient();
  const {
    params: { year },
  } = useRouteMatch();
  const onEnter = (section) => {
    client.writeQuery({
      query: GET_CURRENT_SECTION,
      data: { currentSection: section },
    });
    const { sectionsVisible } = client.readQuery({ query: GET_SECTIONS });
    const currentSections = new Array(...sectionsVisible);
    currentSections.push(section);
    client.writeQuery({
      query: GET_SECTIONS,
      data: { sectionsVisible: currentSections },
    });
  };

  const onLeave = (section) => {
    const { sectionsVisible } = client.readQuery({ query: GET_SECTIONS });
    const currentSections = sectionsVisible.filter((sec) => sec !== section);
    client.writeQuery({
      query: GET_SECTIONS,
      data: { sectionsVisible: currentSections },
    });
  };

  return (
    <>
      <h1>Navigating the {year} ADR</h1>
      <h2 id="adr-format">{year} format</h2>
      <Waypoint
        onEnter={() => onEnter("2020 ADR format")}
        onLeave={() => onLeave("2020 ADR format")}
        fireOnRapidScroll={false}
      >
        <article>
          <p>
            <video
              src="/usrds_adr_tutorial.mp4"
              controls={true}
              width="60%"
              poster={poster}
              className="player-wrapper"
            />
            The United States Renal Data System {year} Annual Data Report (ADR)
            is presented in a novel format on an entirely new platform. The core
            purpose of the ADR – to describe the epidemiology of kidney disease
            in the U.S. in support of public health, research, and policy
            initiatives to improve the care of individuals with acute kidney
            injury, chronic kidney disease, and end-stage renal disease – has
            not changed. The new format is intended to support and enhance this
            core function by making the presentation more accessible, flexible,
            and user-friendly. The ADR has been transformed from a static
            document to a dynamic and interactive experience. The relationship
            between graphical content and text has been reimagined so that
            tables and figures take center stage, with descriptive text
            juxtaposed for easy reference. Figures can be manipulated to display
            data of most interest. For example, many figures allow the reader to
            choose among presentations of counts, unadjusted rates, or adjusted
            rates. In other cases, readers can choose to display data stratified
            by patient characteristics or within subgroups of interest with
            automatic rescaling of axes as necessary for optimal viewing.
            Readers can download figures in their original form or in the
            customized form they have created. Please watch the video on this
            page for a brief tutorial about how to customize viewing and
            exporting of {year} ADR content.
          </p>
        </article>
      </Waypoint>
      {year === "2020" && (
        <>
          <h2 id="2020-adr-content" style={{ clear: "both" }}>
            {year} ADR content
          </h2>
          <Waypoint
            onEnter={() => onEnter("2020 ADR content")}
            onLeave={() => onLeave("2020 ADR content")}
            fireOnRapidScroll={false}
          >
            <article>
              <p>
                ADR content has also been updated and will continue to evolve in
                coming years. We will introduce contemporary topics while
                maintaining focus on key surveillance functions. The emerging
                topics we introduce this year center on the experiences of
                patients with kidney disease and the effects of coronavirus
                disease 2019 (COVID-19). A new Patient Experience chapter
                focuses on patients’ experience of symptoms, understanding of
                prognosis, and end-of-life preferences, and explores
                inter-relations among these factors. The COVID-19 pandemic has
                presented an unexpected and urgent need for surveillance and
                investigation. Although traditional sources of data used in
                preparation of the ADR involve an 18-month lag, largely due to
                processing of Medicare claims, we have used quarterly data
                extracts from the Centers for Medicare & Medicaid Services’
                Renal Management Information System—anchored by the ESRD Medical
                Evidence Report and the ESRD Death Notification—to examine
                changes in mortality, hospitalization, and onset of ESRD during
                the early period of the pandemic.
              </p>
              <p>
                This year represents the beginning of a new phase in data
                sharing among government and non-government entities, which
                presents challenges and opportunities. As a result, this year’s
                ADR does not contain data from private insurers. However, we
                anticipate that future ADRs will include these data and will
                also incorporate additional data sources to more fully
                characterize non-Medicare populations with kidney disease.{" "}
              </p>
            </article>
          </Waypoint>
        </>
      )}
    </>
  );
};

export default AboutTheNewADR;

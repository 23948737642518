import React from 'react'

const MobileFooterTitle = ({ figure }) => {

    return (
        <span className="section-nav-figure-title">
            {figure.figureNumber} - {figure.shortTitle}
        </span>
    )
}

export const FooterHeadliner = ({ figure }) => {

    //const figOpts = useSelector(state => state.graphState[figure.figureNumber])

    return (
        <span className="section-nav-figure-title">
            {figure.figureNumber} - {figure.shortTitle}
        </span>
    )
}

export default MobileFooterTitle
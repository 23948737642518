import { DATA_SET_CHANGE } from '../actions/graphs'

const graph = (state = {}, action) => {
    if (action.type === DATA_SET_CHANGE) {
        const newState = {
            ...state,
            [action.payload.figureNumber]: {
                ...action.payload
            }
        }
        return newState;
    }
    return state;
}

export default graph
import React from "react";
import Graph from "./graphComponents/Graph";
import Highcharts from "./HighchartsMixins";

const Venn = ({ options, size }) => {
  return (
    <>
      <Graph highcharts={Highcharts} options={options} size={size} />
    </>
  );
};

export default Venn;

import React, { useEffect, useRef } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  Redirect,
} from "react-router-dom";
import { useQuery } from "@apollo/client";
import ChapterContent from "./ChapterContent";
import VolumeContent from "./VolumeContent";
import { GET_OUTLINE } from "../../queries/outlineData";

import "./Chapter.scss";
import Landing from "./Landing";
import AboutTheNewADR from "./AboutTheNewADR";
import ReferenceTables from "./referenceTables/ReferenceTables";
import HealthyPeople2020 from "./HealthyPeople2020";
import IntroductionToVolume2 from "./IntroductionToVolume2";
//import Logo from '../nav/Logo';

import ErrorFallback from "../content/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { isContentChapter } from "../../utils/chapterFormat";
import ESRDAnalyticalMethods from "./analyticalMethods/ESRDAnalyticalMethods";
import CKDAnalyticalMethods from "./analyticalMethods/CKDAnalyticalMethods";
import HealthyPeople2030 from "./HealthyPeople2030/HealthyPeople2030";
import Introduction from "./Introduction/Introduction";
import SupplementsMethods from "./analyticalMethods/SupplementsAnalyticalMethods";

import Footer from "../../components/footer/Footer"


const Chapter = () => {
  const {
    params: { year, volume, chapter },
  } = useRouteMatch("/:year/:volume?/:chapter?");
  const { data } = useQuery(GET_OUTLINE);

  const breadCrumb = {
    volume: null,
    chapter: null,
  };

  const currentVol =
    data && data.volumes
      ? data.volumes.filter((x) => x.slug === volume)[0]
      : null;
  const currentChap = currentVol
    ? currentVol.chapters.filter((x) => x.slug === chapter)[0]
    : null;

  if (data && data.volumes && data.volumes.length) {
    const volumeTitle = currentVol;
    if (volumeTitle) {
      breadCrumb.volume = {
        title: volumeTitle.title,
        slug: volumeTitle.slug,
      };
      const chapterTitle = volumeTitle.chapters.filter(
        (x) => x.slug === chapter
      )[0];
      if (chapterTitle) {
        breadCrumb.chapter = {
          title: chapterTitle.title,
          chapterNumber: chapterTitle.chapterNumber,
        };
      }
    }
  }

  const footerMargin =
    currentVol && currentChap && isContentChapter(currentChap, currentVol)
      ? "footer-margin"
      : "";
  const history = useHistory();
  const mainRef = useRef();

  let sideNavCss = "";
  if ((!volume && !chapter) || volume === "reference-tables") {
    sideNavCss = "hide-side-nav";
  }

  // scroll back to the top of the page when the move from chapter to chapter.
  useEffect(() => {
    const unlisten = history.listen((loc) => {
      if (!loc.hash) {
        window.scrollTop = 0;
        window.scrollLeft = 0;
        if (mainRef.current) {
          mainRef.current.scrollTop = 0;
          mainRef.current.scrollLeft = 0;
        }
      } else {
        // this is sadly, the only thing that works :(
        const id = loc.hash.replace("#", "");
        const elemToScrollTo = document.getElementById(id);
        const main = document.getElementById("main");
        if (main && elemToScrollTo) {
          main.scrollTop = elemToScrollTo.offsetTop;
        }
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <main
      id="main"
      ref={mainRef}
      className={`main long-nav ${footerMargin} ${sideNavCss}`}
      tabIndex="0"
    >
      <div className="breadcrumb">
        Annual Data Report
        {breadCrumb.volume ? ` // ${breadCrumb.volume.title}` : ``}
        {breadCrumb.chapter
          ? ` // ${
              breadCrumb.chapter.chapterNumber > 0 &&
              isContentChapter(breadCrumb.chapter, breadCrumb.volume)
                ? breadCrumb.chapter.chapterNumber + ":"
                : ""
            } ${breadCrumb.chapter.title}`
          : ``}
      </div>
      <div className="content-wrapper">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Switch>
            <Route exact path="/:year">
              <Landing />
            </Route>
            <Route exact path="/:year/introduction">
              <Introduction />
            </Route>
            <Route exact path="/:year/about-the-new-adr">
              <AboutTheNewADR />
            </Route>
            <Route exact path="/:year/covid-19-supplement">
              <Redirect
                to={`/${year}/covid-19-supplement/1-covid-19-supplement`}
              />
            </Route>
            <Route exact path="/:year/healthy-people-2020">
              <HealthyPeople2020 />
            </Route>
            <Route exact path="/:year/healthy-people-2030">
              <HealthyPeople2030 />
            </Route>
            <Route exact path="/:year/reference-tables">
              <ReferenceTables />
            </Route>
            <Route
              exact
              path="/:year/chronic-kidney-disease/ckd-analytical-methods"
            >
              <CKDAnalyticalMethods />
            </Route>
            <Route
              exact
              path="/:year/end-stage-renal-disease/esrd-analytical-methods"
            >
              <ESRDAnalyticalMethods />
            </Route>
            <Route
              exact
              path="/:year/supplements-covid-19-disparities/supplements-analytical-methods"
            >
              <SupplementsMethods />
            </Route>
            <Route
              exact
              path="/:year/supplements-disparities/supplements-analytical-methods"
            >
              <SupplementsMethods />              
            </Route>
            <Route exact path="/:year/suggested-citation">
              <section>
                <h1>Suggested citation for this report:</h1>
                <section>
                  United States Renal Data System. {year}{" "}
                  <span style={{ fontStyle: "italic" }}>
                    USRDS Annual Data Report: Epidemiology of kidney disease in
                    the United States.
                  </span>{" "}
                  National Institutes of Health, National Institute of Diabetes
                  and Digestive and Kidney Diseases, Bethesda, MD, {year}.
                  <div className="citation-link">
                    <a
                      href={`${process.env.REACT_APP_BASE_URI}/pdf/citation?year=${year}`}
                      download={`${year}_USRDS_ADR.enw`}
                    >
                      Download EndNote citation file
                    </a>
                    .
                  </div>
                </section>
              </section>
            </Route>
            <Route
              exact
              path="/:year/end-stage-renal-disease/introduction-to-volume-2"
            >
              <IntroductionToVolume2 />
            </Route>
            <Route
              exact
              path="/:year/:volume"
              render={() => {
                return <VolumeContent />;
              }}
            />
            <Route
              exact
              path="/:year/:volume/:chapter"
              render={(...x) => {
                return <ChapterContent outline={data} />;
              }}
            ></Route>
          </Switch>
        </ErrorBoundary>
      </div>
      <Footer />
    </main>
  );
};

export default Chapter;

import React from "react";

import config from "../../../config/config";

import "./ReferenceTables.scss";

const ReferenceTables2021 = () => {
  return (
    <>
      <h1>Reference Tables</h1>
      <section className="ref-tables-section">
        <ul>
          <li>
            <span className="ref-tables-volume">Volume 1 - CKD:</span>
            <ul>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=CKD_Ref_B_ Prevalence_2021`}
                >
                  B. Prevalence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=CKD_Ref_K_Expenditures_2021`}
                >
                  K. Expenditures
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span className="ref-tables-volume">Volume 2 - ESRD:</span>
            <ul>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_A Incidence_2021`}
                >
                  A. Incidence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_B_Prevalence_2021`}
                >
                  B. Prevalence
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_C_PatientChars_2021`}
                >
                  C. Patient Characteristics
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_D_Modality_2021`}
                >
                  D. Treatment Modalities
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_E_transplantProcess_2021`}
                >
                  E. Transplantation: Process
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_F_TransplantOutcomes_2021`}
                >
                  F: Transplantation: Outcomes
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_G_Hospitalization_2021`}
                >
                  G. Morbidity and Hospitalization
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_H_Mortality_2021`}
                >
                  H. Mortality and Causes of Death
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_I_Survival_2021`}
                >
                  I. Patient Survival
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_J_Providers_2021`}
                >
                  J. Provider Characteristics
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_K_Costs_2021`}
                >
                  K. Expenditures for ESRD Patients
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_L_VascularAccess_2021`}
                >
                  L. Vascular Access
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_M_Census_2021`}
                >
                  M. Census Populations
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=ESRD_Ref_N_International_2021`}
                >
                  N. International Comparisons
                </a>
              </li>
              <li>
                <a
                  href={`${config.baseUri}/referenceTables?year=2021&referenceTable=Reference Table Methods`}
                >
                  Analytical Methods
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
	  <p>
        <br/>
        <b>**Error Notification**</b><br/><br/>
        Reference Tables A.4 and A.9 in the 2021 Annual Data Report were found to contain a small number of errors. 
        These errors have now been corrected and the updated tables have been posted.
      </p>
    </>
  );
};

export default ReferenceTables2021;

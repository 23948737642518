// eslint-disable-next-line
const TOGGLE = '${toggle}'
// eslint-disable-next-line
const TAB = '${tab}'

export const replaceToggle = (toggleValue, text) => {
   
    if (!toggleValue && text) {
        let noToggle = text.replace(TOGGLE, '')
        noToggle = noToggle.trim()
        return noToggle.charAt(0).toUpperCase() + noToggle.slice(1);
    }
    if(text)
    {
       
        let newText = text.replace(TOGGLE, toggleValue);

        if(newText.toLowerCase().includes("adjusted") && !newText.startsWith("Adjusted") && !newText.startsWith("Unadjusted")){
            newText = newText.replace("Adjusted", "adjusted").replace("Unadjusted ", "unadjusted ");
        }
        else if(newText.includes("By Age (years)"))
        {
            newText = newText.replace("By Age (years)", "by age (years)").replace("and Sex", "and sex").replace("and Race", "and race")
        }
        
        return newText;
    }
    
    return text;
}

export const replaceTab = (tabValue, text) => {
    if (!tabValue && text) {
        let noTab = text.replace(TAB, '')
        noTab = noTab.trim()
        return noTab.charAt(0).toUpperCase() + noTab.slice(1);
    }
    if (text)
    {
        return text.replace(TAB, tabValue)
    }
    
    return text;
}
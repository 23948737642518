import React from 'react';
import { Nav } from 'react-bootstrap';
import './MobileNav.scss';
import { useState } from 'react';

const MobileNavContet = ({ volumes, year, volume, chapter }) => {

    const initialVolume = volumes ? volumes.filter(x => x.slug === volume)[0] : null
    const [currentVolume, setCurrentVolume] = useState(initialVolume)

    const resetVolume = (newVol) => {
        if (newVol.chapters && newVol.chapters.length) {
            const initialChapterSlug = newVol.chapters[0].slug
            setChapterSlug(initialChapterSlug)
        }
        setCurrentVolume(newVol)
    }

    // TODO: Redirect instead of picking first section: ask about home, volume, chapter, section landing pages
    const currentChapter = currentVolume
        && currentVolume.chapters ? currentVolume.chapters.filter(x => x.slug === chapter)[0] : null
    // eslint-disable-next-line
    const [chapterSlug, setChapterSlug] = useState(currentChapter ? currentChapter.slug : null)

    return (
        <>
            {volumes ?
                volumes.map(vol => {
                    return (
                        <Nav.Item key={vol.slug}>
                            <Nav.Link
                                active={currentVolume && vol.slug === currentVolume.slug} key={vol.slug}
                                href={`/${year}/${vol.slug}`}
                                onClick={() => resetVolume(vol)}>
                                {vol.title}
                            </Nav.Link>
                        </Nav.Item>
                    )
                })
                : <></>
            }

        </>
    );
}

export default MobileNavContet
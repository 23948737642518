import React from "react";
import { useParams } from "react-router-dom";

import config from "../../../config/config";

import "./AnalyticalMethods.scss";

const CKDAnalyticalMethods2022 = () => {
  const { year } = useParams();

  const download = (refNum) => {
    const url = `${config.baseUri}/analyticalMethods?year=${year}&fileName=${refNum}&volumeNumber=1`;
    window.location = url;
  };

  return (
    <div>
      <h1>Analytical Methods</h1>
      <ul>
        <li>
          <button onClick={() => download("Volume 1 Methods")}>
            Data Sources and Methods for CKD Volume
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              download("Codes for Hospitalization Causes - CKD Volume")
            }
          >
            Codes for Hospitalization Causes - CKD Volume
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              download("Codes and Algorithm for CVD and Procedure")
            }
          >
            Codes and Algorithm for CVD and Procedure
          </button>
        </li>
        <li>
          <button onClick={() => download("v1_CKD_Comorbidity_ICD9_10_Codes")}>
            v1_CKD_Comorbidity_ICD9_10_Codes
          </button>
        </li>
        <li>
          <button
            onClick={() =>
              download("Codes for Comorbidity and Kidney Disorders in Children")
            }
          >
            Codes for Comorbidity and Kidney Disorders in Children
          </button>
        </li>
      </ul>
    </div>
  );
};

export default CKDAnalyticalMethods2022;

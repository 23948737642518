export const toolTipFormatter = function (decimalPlaces, chartType) {
  return function () {
    if (!this.points) {
      let sn =
        this.series.name && this.series.name !== "Series 1"
          ? "<b>" + this.series.name + ":</b> "
          : "";
      let val = this.y === undefined ? this.point.value : this.y;
      if (chartType.indexOf("VENN") > -1) {
        sn = "<b>" + this.point.options.labelText + ":</b> ";
        if (this.point.options.labelText === "None") {
          val = this.point.noneValue;
        }
      }
      if (chartType === "PIE CHART") {
        sn = "<b>" + this.point.options.name + ":</b> ";
      }
      const color = `<span style="color:${this.point.color}">● </span>`;

      if (chartType === "FOREST PLOT") {
        return `${color}${sn} ${stringFormatter(
          this.point.mid,
          decimalPlaces
        )} (${stringFormatter(
          this.point.low,
          decimalPlaces
        )}, ${stringFormatter(this.point.high, decimalPlaces)})`;
      } else {
        return color + sn + stringFormatter(val, decimalPlaces) + "";
      }
    } else {
      if (
        chartType.indexOf("BOX PLOT") > -1 &&
        this.points?.find((p) => p.point)?.point?.low !== undefined
      ) {
        return [this.x].concat(
          this.points.map((p) => {
            let tooltipStr = "";
            let sn =
              p.series.name && p.series.name !== "Series 1"
                ? `<span style="color:${p.color}">● </span><b>${p.series.name}</b><br>`
                : "";
            tooltipStr += sn;
            // q3
            tooltipStr += `<span>Upper quartile: ${stringFormatter(
              p.point.q3,
              decimalPlaces
            )}</span><br>`;
            // median
            tooltipStr += `<span>Median: ${stringFormatter(
              p.point.median,
              decimalPlaces
            )}</span><br>`;
            // q1
            tooltipStr += `<span>Lower quartile: ${stringFormatter(
              p.point.q1,
              decimalPlaces
            )}</span><br>`;
            // mean
            if (p.point.mean !== null && p.point.mean !== undefined) {
              tooltipStr += `<span><b>Mean</b>: ${stringFormatter(
                p.point.mean,
                decimalPlaces
              )}</span>`;
            }
            return tooltipStr;
          })
        );
      }
      return [this.x].concat(
        this.points.map((p) => {
          const color = `<span style="color:${p.color}">● </span>`;
          const val = p.y === undefined ? p.point.value : p.y;
          const sn =
            p.series.name && p.series.name !== "Series 1"
              ? "<b>" + p.series.name + ":</b> "
              : "";
          if (p.y >= 10000) {
            return (
              color +
              sn +
              val.toLocaleString(undefined, {
                maximumFractionDigits: mapTooltopFormats[decimalPlaces],
                minimumFractionDigits: mapTooltopFormats[decimalPlaces],
              })
            );
          } else {
            return (
              color + sn + val.toFixed(mapTooltopFormats[decimalPlaces]) + ""
            );
          }
        })
      );
    }
  };
};

const stringFormatter = (value, decimalPlaces) => {
  if (isNaN(value)) return ``;
  if (value >= 10000) {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: mapTooltopFormats[decimalPlaces],
      minimumFractionDigits: mapTooltopFormats[decimalPlaces],
    });
  } else {
    return value.toFixed(mapTooltopFormats[decimalPlaces]) + "";
  }
};

export const yAxisFormatter = function (decimalPlaces) {
  return function () {
    if (this.value >= 10000) {
      return this.value.toLocaleString(undefined, {
        maximumFractionDigits: mapTooltopFormats[decimalPlaces],
        minimumFractionDigits: mapTooltopFormats[decimalPlaces],
      });
    } else {
      return this.value.toFixed(mapTooltopFormats[decimalPlaces]) + "";
    }
  };
};

export const sharedTooltipFormatter = function (
  decimalPlaces,
  chartType,
  reversed
) {
  return function () {
    let tt = `${this.x.name ? this.x.name : ""}`;
    if (this.x.parent && this.x.parent.name) {
      tt += `, ${this.x.parent.name}`;
    }
    tt += "<br/>";
    const labels = this.points.map((p, i) => {
      const yVal = p.y.toFixed(mapTooltopFormats[decimalPlaces]);
      return i
        ? `<span style="color:${p.color}">● </span><b>${p.series.name}:</b> ${yVal}<br/>`
        : `<span style="color:${p.color}">● </span><b>${p.series.name}:</b> ${yVal}`;
    });
    if (!reversed) {
      for (let i = this.points.length - 1; i > -1; i--) {
        tt += labels[i];
      }
    } else {
      for (let i = 0; i < this.points.length; i++) {
        if (i === 0) {
          tt += labels[i] + `<br/>`;
        } else {
          tt += labels[i];
        }
      }
    }
    return tt;
  };
};

export const mapTooltopFormats = {
  // eslint-disable-next-line
  ["0.0001"]: 4,
  // eslint-disable-next-line
  ["0.001"]: 3,
  // eslint-disable-next-line
  ["0.01"]: 2,
  // eslint-disable-next-line
  ["0.1"]: 1,
  // eslint-disable-next-line
  ["1"]: 0,
};

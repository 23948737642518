import React from "react";
import { useQuery, useApolloClient } from "@apollo/client";
import { getSectionsByChapterId } from "../../queries/sections";
import { GET_SECTIONS } from "../../queries/sections";

const ChapterNavContent = ({ selectedChapter }) => {
  const client = useApolloClient();

  const {
    data: secData,
    loading,
    error,
  } = useQuery(getSectionsByChapterId(selectedChapter.id));
  const {
    data: { sectionsVisible },
  } = useQuery(GET_SECTIONS);
  const sectionVisible = sectionsVisible[0];
  const sections = secData ? secData.sections : null;

  const onNav = (e, section) => {
    if (document.getElementById(section.slug)) {
      document.getElementById(section.slug).scrollIntoView();
    }
    client.writeQuery({
      query: GET_SECTIONS,
      data: { sectionsVisible: [section.title] },
    });
  };

  if (loading) {
    return <div className="informational">Getting chapter content...</div>;
  } else if (error) {
    return (
      <div className="informational">
        There was an error loading section data for this chapter.
      </div>
    );
  } else if (selectedChapter && sections && sections.length < 1) {
    return (
      <div className="informational">
        There are no sections for this given chapter.
      </div>
    );
  }

  return selectedChapter && sections ? (
    sections.map((sec) => {
      return sec.figure && sec.figure.figureNumber ? (
        <div
          className={`figNav figure ${
            sectionVisible === sec.title ? "active" : ""
          }`}
          key={sec.slug}
          onClick={(e) => onNav(e, sec)}
        >
          <span className="figNav-head-text">
            <span className="figNav-figNumber">{sec.figure.figureNumber}</span>
          </span>
          <span>{sec.figure.shortTitle}</span>
        </div>
      ) : (
        <div
          className={`figure ${sectionVisible === sec.title ? "active" : ""}`}
          key={sec.slug}
          onClick={(e) => onNav(e, sec)}
        >
          {sec.title}
        </div>
      );
    })
  ) : (
    <></>
  );
};

export default ChapterNavContent;

import React from "react";
import { useParams } from "react-router";
import CKDAnalyticalMethods2020 from "./CKDAnalyticalMethods2020";
import CKDAnalyticalMethods2021 from "./CKDAnalyticalMethods2021";
import CKDAnalyticalMethods2022 from "./CKDAnalyticalMethods2022";
import CKDAnalyticalMethods2023 from "./CKDAnalyticalMethods2023";
import CKDAnalyticalMethods2024 from "./CKDAnalyticalMethods2024";


const CKDAnalyticalMethods = () => {
  const { year } = useParams();
  if (year === "2020") {
    return <CKDAnalyticalMethods2020 />;
  } else if (year === "2021") {
    return <CKDAnalyticalMethods2021 />;
  } else if (year === "2022") {
    return <CKDAnalyticalMethods2022 />;
  } else if (year === "2023") {
    return <CKDAnalyticalMethods2023 />;
  } else if (year === "2024") {
    return <CKDAnalyticalMethods2024 />;    
  } else {
    return null;
  }
};

export default CKDAnalyticalMethods;

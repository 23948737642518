import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import MobileFooter from './MobileFooter';

const MobileFooterSwitch = () => {
  const { params: { volume, chapter } } = useRouteMatch("/:year/:volume?/:chapter?");
  const nonContentChapter = chapter ?
    chapter.indexOf('analytical-methods') > -1 ||
      chapter.indexOf('introduction-to-volume-2') > -1
      ? true
      : false
    : true
  return volume && chapter && !nonContentChapter ? <MobileFooter /> : <></>
}

export default MobileFooterSwitch
import { useEffect } from "react";

export default function useOutsideClick(ref, cb, ...okRefs) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)
                      && okRefs.every(r => {
                          return !r.current.contains(event.target)
                        })) {
          cb();
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref, cb, okRefs]);
}
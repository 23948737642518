import { RESPONSIVE_BREAK } from "../../../../../utils/constants";
import {
  mapTooltopFormats,
  sharedTooltipFormatter,
} from "../../../../../utils/numberFormatters";

export const LBL_ROT_AMT = -90;

export const setAreaChartOptions = (initialOptions, data, reverse) => {
  initialOptions = enable100PercentageSharedToolTip(
    initialOptions,
    data.dataLabelDecimalPlaces,
    !!reverse
  );
  initialOptions.yAxis.reversedStacks = !!reverse;
  initialOptions.plotOptions.area = {
    ...initialOptions.plotOptions.area,
    marker: {
      enabled: false,
    },
    states: {
      hover: {
        enabled: false,
      },
    },
  };
  initialOptions.xAxis.crosshair = {
    color: "black",
  };
  return initialOptions;
};

export const rotateSecondXAxis = (
  initialOptions,
  useResponsiveBreak,
  rotateSingleCategory
) => {
  if (!useResponsiveBreak || window.innerWidth < RESPONSIVE_BREAK) {
    if (
      initialOptions.xAxis.categories.every((cat) => typeof cat === "object") &&
      !rotateSingleCategory
    ) {
      initialOptions.xAxis.labels.rotation = LBL_ROT_AMT;
      initialOptions.xAxis.labels.groupedOptions = [
        {
          rotation: 0,
        },
      ];
    }
    if (
      !rotateSingleCategory &&
      initialOptions.xAxis.categories.every((cat) => typeof cat === "string")
    ) {
      initialOptions.xAxis.labels.rotation = 0;
    }
  }

  return initialOptions;
};

export const percentStackChartFix = (initialOptions) => {
  initialOptions.yAxis.showLastLabel = false;
  initialOptions.yAxis.tickPositioner = function () {
    return this.tickPositions.concat([105]);
  };
};

export const enable100PercentageSharedToolTip = (
  initialOptions,
  decimalPlaces,
  reversed
) => {
  initialOptions.tooltip.split = false;
  initialOptions.tooltip.shared = true;
  initialOptions.tooltip.formatter = sharedTooltipFormatter(
    decimalPlaces,
    null,
    reversed
  );
  initialOptions.tooltip.valueDecimals = mapTooltopFormats[decimalPlaces];
  return initialOptions;
};

export const fullStackLabelsOnlyFormatter = function () {
  const allVisible = this.axis.chart.series
    .map((x) => x.visible)
    .every((y) => y);
  if (!allVisible) {
    return "";
  } else {
    return this.total.toFixed(1) + "";
  }
};

export function xAxisLabelsFormatter() {
  if (typeof this.value === "object") {
    return Number(this.value.userOptions).toFixed(1) + "";
  } else {
    return Number(this.value).toFixed(1) + "";
  }
}

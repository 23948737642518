import { isArray } from "lodash";
import { RESPONSIVE_BREAK } from "../../../../../utils/constants";
import { nonNullMerge } from "./customMerge";
import { enable100PercentageSharedToolTip } from "./figureSpecificOptions";

const mixinCommands = (initialOptions, data, figJson) => {
  initialOptions = enableSharedTooltip(initialOptions, data, figJson);
  initialOptions = breakWordsXAxis(initialOptions, figJson);
  initialOptions = addSeriesOptions(initialOptions, data, figJson);
  return initialOptions;
};

// put newlines on xaxis labels between words
export const breakWordsXAxis = (initialOptions, figJson) => {
  if (figJson.BREAKWORDSXAXIS) {
    if (
      figJson.BREAKWORDSXAXIS.responsiveBreak === "lg" &&
      window.innerWidth >= RESPONSIVE_BREAK
    ) {
      initialOptions.xAxis.categories = initialOptions.xAxis.categories.map(
        (x) => {
          // if this is a multi-xaxis
          if (x.name && isArray(x.categories)) {
            return {
              name: x.name,
              categories: x.categories.map((y) => y.replace(/ /g, "<br/>")),
            };
          }
          return x.replace(/ /g, "<br/>");
        }
      );
    }
  }
  return initialOptions;
};

export const enableSharedTooltip = (initialOptions, data, figJson) => {
  if (figJson.ENABLESHAREDTOOLTIP) {
    initialOptions = enable100PercentageSharedToolTip(
      initialOptions,
      figJson.ENABLESHAREDTOOLTIP.decimalPlaces ?? data.dataLabelDecimalPlaces,
      figJson.ENABLESHAREDTOOLTIP.reversed
    );
  }
  return initialOptions;
};

export const addSeriesOptions = (initialOptions, data, figJson) => {
  if (figJson.SERIES) {
    if (figJson.SERIES.name) {
      const seriesNames = Object.keys(figJson.SERIES.name);
      if (
        seriesNames.length === 1 &&
        seriesNames.find((x) => x === "default")
      ) {
        const defaultSeries = figJson.SERIES.name.default;
        initialOptions.series[0] = nonNullMerge(
          initialOptions.series[0],
          defaultSeries
        );
      } else {
        for (let seriesName of seriesNames) {
          const seriesOptions = figJson.SERIES.name[seriesName];
          let namedSeries = initialOptions.series.find(
            (x) => x.name === seriesName
          );
          namedSeries = nonNullMerge(namedSeries, seriesOptions);
        }
      }
    }
  }
  return initialOptions;
};

export default mixinCommands;

import Highcharts from "highcharts";
import VennFix from "../../../../utils/highchartsVennFix";
import { highchartsBoxPlot } from "../../../../utils/highchartsBoxPlot";
import { highchartsForestPlot } from "../../../../utils/highchartsForestPlot";

const originalAxisRenderer = Highcharts.Axis.prototype.render;

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/offline-exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/venn")(Highcharts);
require("highcharts/modules/map")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts-grouped-categories/grouped-categories")(Highcharts);
VennFix(Highcharts);
highchartsBoxPlot(Highcharts);
highchartsForestPlot(Highcharts);

(function (H) {
  H.wrap(H.Axis.prototype, "render", function (f) {
    if (!this.isGrouped) {
      return originalAxisRenderer.call(this);
    }
    return f.call(this);
  });
})(Highcharts);

export default Highcharts;

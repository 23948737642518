import React from 'react';
import { useRouteMatch } from 'react-router-dom'
import ChapterDetails from './ChapterDetails';
import isHiddenChapter from '../../utils/hiddenChapter';

const ChapterSummary = ({ outline }) => {

    const data = outline
    // match up current path with what sections are currently available
    const { params: { volume, chapter } } = useRouteMatch('/:year/:volume?/:chapter?')

    const selectedVolume = data && data.volumes && volume ? data.volumes.filter(vol => vol.slug === volume)[0] : null;

    const selectedChapter = selectedVolume && selectedVolume.chapters && chapter ?
        selectedVolume.chapters.filter(chap => chap.slug === chapter)[0]
        : null;
    
    return (
        isHiddenChapter(selectedChapter, selectedVolume) ?
        "This chapter is currently not available" :
        selectedChapter ?
            <>


                <ChapterDetails selectedChapter={selectedChapter} selectedVolume={selectedVolume} />
            </>
            : <></>
    )
}

export default ChapterSummary
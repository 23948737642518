export const highchartsForestPlot = (Highcharts) => {
  (function (H) {
    H.seriesTypes.columnrange.prototype.pointArrayMap = ["low", "high", "mid"];

    H.seriesTypes.columnrange.prototype.toYData = function (point) {
      return [point.low, point.high, point.mid];
    };

    H.wrap(H.seriesTypes.columnrange.prototype, "drawPoints", function (p) {
      p.call(this);
      var chart = this.chart,
        r = chart.renderer;

      this.points.forEach((pnt) => {
        var x = pnt.shapeArgs.x + pnt.shapeArgs.width / 2;
        var width = pnt.shapeArgs.height;
        var ratio = (pnt.mid - pnt.low) / (pnt.high - pnt.low);
        var actualY = pnt.shapeArgs.y + width - width * ratio;

        if (pnt.midPoint) {
          pnt.midPoint.attr({
            cx: x,
            cy: actualY,
            r: pnt.shapeArgs.width / 1.5,
          });
        } else {
          pnt.midPoint = r
            .circle(x, actualY, pnt.shapeArgs.width / 1.5)
            .attr({
              stroke: pnt.series.color,
              fill: pnt.series.color,
            })
            .add(pnt.series.group)
            .toFront();
        }
      });
    });
  })(Highcharts);
};

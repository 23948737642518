import React, { useState, useRef } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import Octicon, { ChevronDown, ChevronUp } from "@primer/octicons-react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import useOutsideClick from "../../utils/useOutsideClick";
import { GET_SECTIONS, getSectionsByChapterId } from "../../queries/sections";
import MobileFooterTitle, { FooterHeadliner } from "./MobileFooterTitle";

const MobileFooterContent = ({ currentChapter }) => {
  const {
    data: { sectionsVisible },
  } = useQuery(GET_SECTIONS);
  const { data: sectionData } = useQuery(
    getSectionsByChapterId(currentChapter.id)
  );

  const sections = sectionData && sectionData.sections;

  const currentSection = sectionsVisible[0];
  const [items, setItems] = useState(null);

  const wrapper = useRef(null);
  const nextRef = useRef(null);
  const prevRef = useRef(null);
  useOutsideClick(
    wrapper,
    () => {
      setItems(null);
    },
    nextRef,
    prevRef
  );

  let {
    params: { year, volume, chapter },
  } = useRouteMatch("/:year/:volume?/:chapter?");

  const volumeSlug = volume ? volume : null;
  const chapterSlug = chapter ? chapter : null;
  const sectionSlug = currentSection ? currentSection : null;

  const closeMenu = () => {
    setItems(null);
  };

  const computeNextAndPreviousSection = () => {
    const nextSection =
      sections.map((s) => s.title).indexOf(currentSection) + 1;
    const nextSlug =
      nextSection >= sections.length ? null : `#${sections[nextSection].slug}`;

    const prevSection =
      sections.map((s) => s.title).indexOf(currentSection) - 1;
    let prevSlug = prevSection < 0 ? null : `#${sections[prevSection].slug}`;

    return {
      nextSection: nextSlug,
      prevSection: prevSlug,
    };
  };

  let { nextSection, prevSection } = {};
  if (sections) {
    let pnsec = computeNextAndPreviousSection();
    nextSection = pnsec.nextSection;
    prevSection = pnsec.prevSection;
  }

  const toggleMenu = () => {
    items
      ? setItems(null)
      : setItems(
          <ul className="nav-list" key="nav">
            {sections.map((sec) => (
              <li key={sec.slug}>
                <Link
                  to={`/${year}/${volumeSlug}/${chapterSlug}/#${sec.slug}-wrapper`}
                  className={`dropdown-item ${
                    sectionSlug === sec.slug ? "active" : ""
                  }`}
                  role="button"
                  onClick={() => closeMenu()}
                >
                  {sec.figure && sec.figure.figureNumber ? (
                    <MobileFooterTitle figure={sec.figure} />
                  ) : (
                    sec.title
                  )}
                </Link>
              </li>
            ))}
          </ul>
        );
  };

  const determineFooterHeader = () => {
    // if
    const cachedSectionVisbile =
      sectionData && sectionData.sections && sectionsVisible[0];
    if (cachedSectionVisbile) {
      const fullSection = sectionData.sections.filter(
        (x) => x.title === sectionsVisible[0]
      )[0];
      if (
        fullSection &&
        fullSection.figure &&
        fullSection.figure.figureNumber
      ) {
        //fffffff
        return <FooterHeadliner figure={fullSection.figure} />;
      } else {
        return <div>{sectionsVisible[0]}</div>;
      }
    } else {
      return <div></div>;
    }
  };

  return (
    <nav className="mobile-footer">
      <div className="nav-select">
        <div className="overlay"></div>
        <div
          className="nav-select-link"
          role="button"
          onClick={() => toggleMenu()}
        >
          {/* {
                        (sectionData && sectionData.sections && sectionsVisible[0]) ? sectionsVisible[0] : 
                            (sectionData && sectionData.sections && sectionData.sections.length > 0 && !sectionsVisible[0]) ? sectionData.sections[0].title : null
                    } */}
          {determineFooterHeader()}
        </div>
        {nextSection ? (
          <a href={nextSection} className={"nav-next-section"}>
            <div role="button" aria-label="Next Section" ref={nextRef} disabled>
              <Octicon icon={ChevronDown} />
            </div>
          </a>
        ) : (
          <span className="nav-next-section disabled">
            <div role="button" aria-label="Next Section" ref={nextRef} disabled>
              <Octicon icon={ChevronDown} />
            </div>
          </span>
        )}
        {prevSection ? (
          <a href={prevSection} className={"nav-prev-section"}>
            <div role="button" aria-label="Previous Section" ref={prevRef}>
              <Octicon icon={ChevronUp} />
            </div>
          </a>
        ) : (
          <span className="nav-prev-section disabled">
            <div
              role="button"
              aria-label="Previous Section"
              ref={prevRef}
              disabled
            >
              <Octicon icon={ChevronUp} />
            </div>
          </span>
        )}
      </div>
      <div ref={wrapper} className="flyout">
        <CSSTransitionGroup
          transitionName="nav-list"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {items ? items : <></>}
        </CSSTransitionGroup>
      </div>
    </nav>
  );
};

export default MobileFooterContent;

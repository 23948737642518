import React from "react";
import Config from "./config/config";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  gql,
} from "@apollo/client";
import getYear from "./utils/getYear";

const LOCAL_FIELDS = gql`
  {
    sectionsVisible
    graphsVisible
    sectionGraphs
    showSticky
    currentSection
    outline
  }
`;

const ApolloWrapper = ({ children }) => {
  const cache = new InMemoryCache({
    typePolicies: {
      XAxisType: {
        fields: {
          categories(categories, { readField }) {
            const multipleCategories = readField("multiCategories");
            const cats = multipleCategories
              ? multipleCategories
              : categories
              ? categories
              : [""];
            return cats;
          },
        },
      },
      StandardSeriesType: {
        fields: {
          marker(mark) {
            if (!mark) return undefined;
          },
        },
      },
    },
  });
  const client = new ApolloClient({
    cache,
    uri: `${Config.baseQli}/${getYear()}`,
    resolvers: {},
    connectToDevTools: process.env.NODE_ENV !== "production" ? true : false,
  });
  client.writeQuery({
    query: LOCAL_FIELDS,
    data: {
      sectionsVisible: [],
      graphsVisible: [],
      sectionGraphs: [],
      showSticky: false,
      currentSection: null,
      outline: null,
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;

import { isArray } from "lodash";

export const colors = [
  "#2A4372",
  "#B2324B",
  "#969FA7",
  "#45BA8f",
  "#66B1CE",
  "#4D1434",
  "#40619D",
  "#857A00",
  "#892346",
  "#59407a",
  "#363632",
];

export const font = ["Arial", "sans-serif"];

export const backgroundColors = {
  // eslint-disable-next-line
  ["#2A4372"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#B2324B"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#969FA7"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#45BA8F"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#66B1CE"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#4D1434"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#40619D"]: "#FFFFFF",
  // eslint-disable-next-line
  ["#857A00"]: "#FFFFFF",
};

export const determinePlotLines = (xAxis, chartType) => {
  if (
    isArray(xAxis.categories) &&
    xAxis.categories.length &&
    isArray(xAxis.categories[0].categories) &&
    (chartType === "Clusted vertical cummulative bar" ||
      chartType === "GROUP STACK COLUMN CHART" ||
      chartType === "GROUP CLUSTER COLUMN CHART" ||
      chartType === "CLUSTER COLUMN CHART")
  ) {
    const noItems =
      xAxis.categories.length * xAxis.categories[0].categories.length;
    const noGroups = noItems / xAxis.categories.length;
    const interval = noGroups - 0.5;
    const lines = xAxis.categories.map((x, idx) => {
      return {
        color: "#ccd6eb", // Color value
        value: (idx + 1) * interval + 0.5 * idx, // Value of where the line will appear
        width: 1, // Width of the line
      };
    });
    lines.splice(lines.length - 1, 1);
    return lines;
  } else {
    return null;
  }
};

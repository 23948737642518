import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tab from "./graphComponents/Tab";
import Header from "./graphComponents/Header";
import Summary from "./graphComponents/Summary";
import PanelInstance from "./PanelInstance";

import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";
import PanelMethods from "./graphComponents/PanelMethods";

const TabAndPanel = ({ serverdata, slug, size }) => {
  const dispatch = useDispatch();

  const data = serverdata;

  const graphState = useSelector(
    (state) => state.graphState[data.figureNumber]
  );

  // determine current possible tab values and select the default one selected
  const tabValues = Array.from(new Set(data.tabPanel.flatMap((x) => x.tab)));
  const cTab = graphState && graphState.tab ? graphState.tab : tabValues[0];
  const [currentTab, setTab] = useState(cTab);

  const initialPanel =
    data && data.tabPanel
      ? data.tabPanel.filter((x) => x.tab === currentTab)[0]
      : null;

  const [initPanel, setInitPanel] = useState(initialPanel);

  const tabDataSet = (newTab) => {
    setTab(newTab);

    setInitPanel(data.tabPanel.filter((x) => x.tab === newTab)[0]);

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: newTab,
      },
    });
  };

  const panelLayout = (panels) => {
    let i = 0;
    const divs = [];
    while (i < panels.length) {
      if (i % 2 === 0) {
        if (i + 1 < panels.length) {
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                tab={currentTab}
              />
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i + 1], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                tab={currentTab}
              />
            </div>
          );
        } else {
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                tab={currentTab}
              />
            </div>
          );
        }
      }
      i++;
    }
    return divs;
  };

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header figureData={data} slug={slug} />
          <Tab
            currentTab={currentTab}
            tabDataSet={tabDataSet}
            tabValues={tabValues}
          />
        </>
      ) : (
        <></>
      )}
      <div className="panel-layout">
        <div className="panel-child">
          {initPanel && initPanel.values ? (
            panelLayout(initPanel.values).map((x) => x)
          ) : (
            <></>
          )}
        </div>
      </div>
      <PanelMethods methods={data.methods} />
      <Summary figureData={{ ...data, panel: null }} size={size} />
    </>
  );
};

export default TabAndPanel;

import * as chartConfig from "../../../../utils/highchartsConfig";

const createInitialToggleOptions = () => ({
  chart: {
    style: {
      fontFamily: chartConfig.font,
    },
    height: 500,
  },
  colors: chartConfig.colors,
  yAxis: {
    title: {
      style: {
        color: "#000000",
      },
    },
    labels: {
      style: {
        color: "#000000",
        fontSize: "14px",
      },
    },
  },
  xAxis: {
    title: {
      style: {
        color: "#000000",
      },
    },
    labels: {
      format: "{value:.0f}",
      style: {
        color: "#000000",
        fontSize: "14px",
      },
    },
  },
  plotOptions: {
    line: {
      marker: {
        enabled: false,
      },
      lineWidth: 4,
      fillOpacity: 0.25,
    },
    area: {
      stacking: "normal",
    },
    venn: {
      dataLabels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    scatter: {
      marker: {
        symbol: "square",
        radius: 5,
      },
    },
    pie: {
      dataLabels: {
        alignTo: "toPlotEdges",
        style: {
          fontSize: "12px",
        },
        overflow: "allow",
        distance: 10,
      },
    },
  },
  legend: {
    useHTML: true,
    symbolWidth: 0,
    itemMarginBottom: 7,
    labelFormatter: function () {
      return `<span role="button" class="legend-item"><span>${this.name}</span></span>`;
    },
  },
  credits: {
    text: "",
  },
  tooltip: {
    style: {
      fontSize: "14px",
    },
    padding: 2,
  },
  exporting: {
    csv: {},
    buttons: {
      contextButton: {
        menuItems: [
          "downloadPNG",
          "downloadJPEG",
          "downloadSVG",
          "separator",
          "downloadCSV",
          "downloadXLS",
        ],
      },
    },
  },
});

export default createInitialToggleOptions;

import React from "react";
import { useQuery } from "@apollo/client";
import { GET_OUTLINE } from "../../queries/outlineData";
import { Link, useRouteMatch } from "react-router-dom";
import { getChapterPrefix } from "../../utils/chapterFormat";
import isHiddenChapter, { hiddenVolumeStyle } from "../../utils/hiddenChapter";

const Landing = () => {
  const {
    params: { year },
  } = useRouteMatch();
  const { data, loading, error } = useQuery(GET_OUTLINE);

  if (loading) {
    return (
      <div className="informational">Loading {year} Annual Data Report...</div>
    );
  }

  if (error) {
    return (
      <div className="informational">
        Could not load {year} Annual Data Report.
      </div>
    );
  }

  return (
    <>
      <h1>{year} Annual Data Report</h1>
      <h2>Table of Contents</h2>
      {data && data.volumes && data.volumes.length > 0 ? (
        data.volumes.map((vol) => {
          return (
            <ul key={vol.slug}>
              <li>
                {vol.chapters.length ? (
                  <span>{vol.title}</span>
                ) : (
                  <Link className={`${hiddenVolumeStyle(vol)}`} to={`/${year}/${vol.slug}`}>{vol.title}</Link>
                )}
                <ul>
                  {vol.chapters ? (
                    vol.chapters.map((chap) => {
                      const path = `/${year}/${vol.slug}/${chap.slug}`;
                      return (
                        <Link to={path} key={path}
                        className={`${isHiddenChapter(chap, vol) ? "disabledChapter" : ""}`}>
                          <li>
                            {chap.chapterNumber && chap.chapterNumber > 0
                              ? `${getChapterPrefix(chap, vol)}${chap.title}`
                              : chap.title}
                          </li>
                        </Link>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              </li>
            </ul>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default Landing;

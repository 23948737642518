import gql from "graphql-tag";

export const GET_SECTIONS = gql`
  {
    sectionsVisible @client
  }
`;

export const GET_CURRENT_SECTION = gql`
  {
    currentSection @client
  }
`;

export const getSectionsByChapterId = (id) => {
  return gql`
    {
      sections(chapterId: "${id}"){
        id
        slug
        title
        order
        chapterId
        sectionText {
          id
          sectionId
          text
          order
        }
        figure {
          figureNumber
          title
          shortTitle
          toggleType
          chartType
        }
      }
    }
  `;
};

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_OUTLINE } from "../../queries/outlineData";
import { Nav, Navbar } from "react-bootstrap";
import "./MobileChapter.scss";
import MobileNavContent from "./MobileNavContent";
import { GoogleSearch } from "./GoogleSearch";

const MobileChapter = () => {
  const { data, loading, error } = useQuery(GET_OUTLINE);

  let {
    params: { year, volume, chapter },
  } = useRouteMatch("/:year/:volume?/:chapter?");

  // TODO: Redirect instead of picking first section: ask about home, volume, chapter, section landing pages

  return data && data.volumes ? (
    <div className="chapterdropdown">
      <Navbar
        aria-label="Chapter Navigation"
        id="chapter-mobile-nav"
        expand="xl"
        collapseOnSelect={true}
      >
        {loading ? <div className="informational">Loading...</div> : <></>}
        {error ? (
          <div className="informational">
            An error occured retriving the doucment outline
          </div>
        ) : (
          <></>
        )}

        <Navbar.Toggle
          aria-controls="mobile-menu-navbar-nav"
          className="toggle-broad-menu selector-style">
            <span className="chapter-select">Annual Data Report Chapters</span>
        </Navbar.Toggle>
        <Navbar.Collapse id="mobile-menu-navbar-nav" className="mobile-menu selector-menu">
          <Nav className="chapter-nav">
            <Nav.Item key="home">
              <Nav.Link
                  key="homelink"
                  href={`/${year}/`}>
                  {year} Annual Data Report
              </Nav.Link>
            </Nav.Item>
            <Nav.Item key="home">
              <GoogleSearch/>
            </Nav.Item>
            <MobileNavContent
              chapter={chapter}
              volume={volume}
              volumes={data.volumes}
              year={year}
            />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  ) : (
    <></>
  );
};

export default MobileChapter;

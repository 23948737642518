import React from "react";

import "./figure.scss";
import { useState } from "react";
import Header from "./graphComponents/Header";
import Summary from "./graphComponents/Summary";
import TogglePane from "./graphComponents/TogglePane";
import Tab from "./graphComponents/Tab";
import Panel from "./Panel";
import { useDispatch, useSelector } from "react-redux";
import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";

const TabTogglePanel = ({ serverdata, size, slug }) => {
  const data = serverdata;

  const dispatch = useDispatch();
  const graphState = useSelector(
    (state) => state.graphState[data.figureNumber]
  );

  // determine current possible tab values and select the default one selected
  const tabValues = Array.from(
    new Set(data.tabTogglePanel.flatMap((x) => x.tab))
  );
  const cTab = graphState && graphState.tab ? graphState.tab : tabValues[0];
  const [currentTab, setTab] = useState(cTab);

  // determine possible toggle values from the currently selected tab
  const togValues = data.tabTogglePanel.find((y) => y.tab === currentTab);
  const possibleToggleValues = Array.from(
    new Set(togValues ? togValues.values.flatMap((x) => x.toggle) : [])
  );

  const [toggleValues, setToggleValues] = useState(possibleToggleValues);
  const [toggle, setToggle] = useState(
    graphState && graphState.toggle
      ? graphState.toggle
      : possibleToggleValues[0]
  );

  const toggleDataSet = (newToggle) => {
    setToggle(newToggle);

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: currentTab,
        toggle: newToggle,
      },
    });
  };

  const tabDataSet = (newTab) => {
    setTab(newTab);

    // set new possible toggle values for this series
    const toggleSet = data.tabTogglePanel.filter((y) => y.tab === newTab)[0];
    const newPossibleToggleValues = Array.from(
      new Set(toggleSet ? toggleSet.values.flatMap((x) => x.toggle) : [])
    );
    setToggleValues(newPossibleToggleValues);

    // if the current toggle value is in the new possible toggle value list,
    // don't change it, otherwise change it to the default
    let finalToggle = toggle;
    if (newPossibleToggleValues.indexOf(toggle) < 0) {
      finalToggle = newPossibleToggleValues[0];
      setToggle(newPossibleToggleValues[0]);
    }

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        tab: newTab,
        toggle: finalToggle,
      },
    });
  };

  const panels = data.tabTogglePanel
    .find((x) => x.tab === currentTab)
    .values.find((y) => y.toggle === toggle);
  const panelCount = panels?.values?.length ?? panels?.length;

  const dataSet = {
    ...serverdata,
    panel: panels.values.map((z) => ({
      panel: z.panel,
      panelCount,
      toggle,
      tab: currentTab,
      subtitle: z.subtitle,
      values: z.values,
    })),
  };

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header figureData={data} currentToggle={toggle} slug={slug} />
          <Tab
            currentTab={currentTab}
            tabDataSet={tabDataSet}
            tabValues={tabValues}
          />
          <TogglePane
            figureData={data}
            toggle={toggle}
            toggleValues={toggleValues}
            toggleDataSet={toggleDataSet}
            size={size}
          />
        </>
      ) : (
        <></>
      )}
      <Panel serverdata={dataSet} size={size} slug={slug} />
      <Summary figureData={data} size={size} />
    </>
  );
};

export default TabTogglePanel;

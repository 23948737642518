import StackColumnOptions from "./mixins/stackedColumnOptions";
import { determinePlotLines } from "../../../../utils/highchartsConfig";
import { replaceToggle } from "../../../../utils/dynamicHeader";
import { RESPONSIVE_BREAK } from "../../../../utils/constants";
import {
  toolTipFormatter,
  yAxisFormatter,
} from "../../../../utils/numberFormatters";
import { removeNoneCircles } from "../../../../utils/highchartsVennNone";
import HighchartsVennExportFix from "../../../../utils/highchartsVennExportFix";
import { replaceHdr } from "./graphComponents/Header";
import mixins from "./mixins/mixins";
import removeNoneLabels from "../../../../utils/removeNoneLabels";
import getYear from "../../../../utils/getYear";

const setInitialFigureOptions = (
  initialOptions,
  data,
  initSeries,
  size,
  initTab,
  initialToggle,
  panelTab
) => {
  let initWidth = initialOptions.chart.width;
  let initHeight = initialOptions.chart.height;

  if (
    data.toggleType.indexOf("PANEL") > -1 &&
    window.innerWidth >= RESPONSIVE_BREAK
  ) {
    if (
      initSeries.panelCount > 1 ||
      (initSeries.data &&
        initSeries.data.length &&
        initSeries.data[0].panelCount > 1)
    ) {
      initWidth = 375;
    } else {
      initWidth = 600;
    }
    if (data.chartType === "PIE CHART") {
      initHeight = 375;
    }
  }

  if (
    window.innerWidth >= RESPONSIVE_BREAK &&
    data.chartType.indexOf("VENN") > -1 &&
    !(data.toggleType.indexOf("PANEL") > -1)
  ) {
    initWidth = 800;
  }

  let init = {
    ...initialOptions,
    tab: initTab,
    toggle: initialToggle,
    panel: panelTab,
    chart: {
      ...initialOptions.chart,
      type: mapChartType[data.chartType],
      width: size === "micro" ? null : initWidth,
      height: size === "micro" ? null : initHeight,
      marginTop: size === "micro" ? 30 : initialOptions.chart.marginTop,
      events: {
        load: removeNoneCircles(data, { tab: initTab, toggle: initialToggle }),
        redraw: removeNoneCircles(data, {
          tab: initTab,
          toggle: initialToggle,
        }),
        render: removeNoneLabels(data.figureNumber, data, initTab),
        exportData: HighchartsVennExportFix(data),
      },
    },
    title: {
      text: initSeries.subtitle
        ? initSeries.subtitle
        : panelTab
        ? panelTab
        : initTab
        ? initTab
        : "",
    },
    caption: {
      ...initialOptions.caption,
      useHTML: true,
      text: data.toggleType.indexOf("PANEL") > -1 ? "" : data.methods,
    },
    yAxis: {
      ...initialOptions.yAxis,
      title: {
        ...initialOptions.yAxis.title,
        text: data.yAxisLabel,
      },
      labels: {
        ...initialOptions.yAxis.labels,
        format: mapValueFormats[data.yAxisDecimalPlaces],
        formatter: yAxisFormatter(data.yAxisDecimalPlaces),
      },
    },
    xAxis: {
      labels: {
        ...initialOptions.xAxis.labels,
        style: {
          fontSize:
            data.chartType === "Clusted vertical cummulative bar" ||
            data.chartType === "GROUP STACK COLUMN CHART"
              ? "14px"
              : initialOptions.xAxis.labels.style.fontSize,
        },
        step:
          data.chartType === "Clusted vertical cummulative bar" ||
          data.chartType === "GROUP STACK COLUMN CHART"
            ? 1
            : 1,
      },
    },
    tooltip: {
      ...initialOptions.tooltip,
      formatter: toolTipFormatter(data.dataLabelDecimalPlaces, data.chartType),
      // Cannot read property 'pos' of undefined
      split:
        data.chartType === "VENN DIAGRAM" || data.chartType === "PIE CHART"
          ? false
          : true,
      style: {
        visibility: data.chartType.indexOf("BOX PLOT" > -1)
          ? "inherit"
          : undefined,
      },
    },
    series: initSeries.data ?? initSeries.dataSet,
    exporting: {
      ...initialOptions.exporting,
      enabled: !(size === "micro"),
      filename: data.figureNumber,
      sourceHeight: 567,
      sourceWidth: 1024,
      chartOptions: {
        chart: {
          events: {
            load: removeNoneCircles(
              data,
              { tab: initTab, toggle: initialToggle },
              true
            ),
            redraw: removeNoneCircles(
              data,
              { tab: initTab, toggle: initialToggle },
              true
            ),
          },
        },
        title: {
          text:
            data.figureNumber + " " + replaceToggle(initialToggle, data.title),
          align: "left",
          widthAdjust: -100,
        },
        colors: initialOptions.colors,
        subtitle: {
          text: replaceHdr(
            initSeries.subtitle
              ? initSeries.subtitle
              : panelTab
              ? panelTab
              : initTab
              ? initTab
              : ""
          ),
        },
        caption: {
          text: `Data Source: ${getYear()} United States Renal Data System Annual Data Report`,
        },
        legend: {
          symbolPadding: 5,
          symbolHeight: 16,
          symbolWidth: 16,
          itemDistance: 50,
        },
      },
    },
  };

  if (
    data.chartType === "STACK COLUMN CHART" ||
    data.chartType === "STACKED COLUMN CHART" ||
    data.chartType === "Clusted vertical cummulative bar" ||
    data.chartType === "GROUP STACK COLUMN CHART" ||
    data.chartType === "STACK CLUSTER COLUMN CHART" ||
    data.chartType === "COLUMN CHART" ||
    data.chartType === "STACK BAR CHART"
  ) {
    init = StackColumnOptions(init, data.yAxisDecimalPlaces);
  }

  if (data.chartType === "FOREST PLOT") {
    init.chart.inverted = true;
  }

  //init = mixins(init, data, initialToggle ?? panelTab, initTab);
  init = mixins(init, data, {
    tab: initTab,
    toggle: initialToggle,
    panel: panelTab,
  });

  if (!init.xAxis.plotLines) {
    init.xAxis.plotLines = determinePlotLines(init.xAxis, data.chartType);
  }

  return init;
};

const mapChartType = {
  // line
  "LINE CHART": "line",

  // regular column
  "COLUMN CHART": "column",

  // no grouping, stacking
  "STACK COLUMN CHART": "column",
  // grouping, stacking
  "Clusted vertical cummulative bar": "column",
  "GROUP STACK COLUMN CHART": "column",
  // grouping, no stacking
  "Clusted vertical Bar Chart": "column",
  "Clusted vertical bar": "column",
  "CLUSTER COLUMN CHART": "column",
  "GROUP CLUSTER COLUMN CHART": "column",

  // Venn diagrams
  "VENN DIAGRAM": "venn",

  // Pie charts
  "PIE CHART": "pie",

  // area
  "STACK AREA CHART": "area",
};

const mapValueFormats = {
  // eslint-disable-next-line
  ["100"]: "{value:00.0f}",
  // eslint-disable-next-line
  ["10"]: "{value:0.0f}",
  // eslint-disable-next-line
  ["1"]: "{value:,.0f}",
  // eslint-disable-next-line
  ["0.1"]: "{value:,.1f}",
  // eslint-disable-next-line
  ["0.01"]: "{value:,.2f}",
  // eslint-disable-next-line
  ["0.001"]: "{value:,.3f}",
};

export default setInitialFigureOptions;

import { gql } from "@apollo/client";

export const figureMetadata = gql`
  fragment figureMetadata on FigureType {
    figureNumber
    figureJson
    toggleType
    chartType
    chapterNumber
    chapterId
    volume
    title
    shortTitle
    subTitle
    summary
    methods
    xAxisLabel
    yAxisLabel
    yAxisDecimalPlaces
    dataLabelDecimalPlaces
  }
`;

export const highchartsConfig = gql`
  fragment highchartsConfig on HighchartsJsonType {
    series {
      ... on StandardSeriesType {
        type
        name
        color
        showInLegend
        data
        zIndex
      }
      ... on BoxPlotSeriesType {
        type
        meanType
        name
        color
        showInLegend
        data
        __typename
      }
      ... on ForestPlotSeriesType {
        type
        name
        color
        showInLegend
        data
        __typename
      }
      ... on VennSeriesType {
        name
        data {
          className
          color
          dataLabels {
            className
          }
          labelText
          name
          noneValue
          panel
          sets
          tab
          toggle
          value
          visible
        }
      }
    }
    xAxis {
      categories
      multiCategories {
        name
        categories
      }
      title {
        text
      }
    }
    title {
      text
    }
    exporting {
      chartOptions {
        subtitle {
          text
        }
      }
    }
  }
`;

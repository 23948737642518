import React from "react";
import Highcharts from "./HighchartsMixins";
import HighchartsReact from "highcharts-react-official";
import mapDataUS from "@highcharts/map-collection/countries/us/us-all.geo.json";
import initOptions from "./initialToggleOptions";
import { mergeFigureJson } from "./mixins/mixins";
import { extractMapSeries } from "./maps/mapUtils";
import * as chartConfig from "../../../../utils/highchartsConfig";
import getYear from "../../../../utils/getYear";

const StateMap = ({ data, tab, toggle }) => {
  const dataPointMapper = (x) => ({
    name: x.name,
    "hc-key": "us-" + x.name.toLowerCase(),
    value: x.data[0],
    x: x.data[0],
  });

  let mapOptions = {
    ...initOptions().style,
    chart: {
      map: mapDataUS,
      type: "map",
      width: !data.tabOnly ? 350 : undefined,
      style: {
        fontFamily: chartConfig.font,
      },
    },
    title: {
      text: data.panel
        ? data.panel.subtitle
          ? data.panel.subtitle
          : data.panel.panel
        : tab,
    },
    caption: {
      useHTML: true,
      text: data.toggleType.indexOf("PANEL") > -1 ? "" : data.methods,
    },
    tooltip: {
      style: {
        fontSize: "14px",
      },
      valueDecimals: mapTooltopFormats[data.dataLabelDecimalPlaces],
      //formatter: toolTipFormatter(data.dataLabelDecimalPlaces, data.chartType),
    },
    colorAxis: {
      type: "linear",
      minColor: "#45ba8f",
      maxColor: "#2a4372",
      stops: [
        [0, "#45ba8f"],
        [0.2, "#00a59a"],
        [0.4, "#008e9f"],
        [0.6, "#00759a"],
        [0.8, "#005c8b"],
        [1, "#2a4372"],
      ],
    },
    credits: {
      text: "",
    },
    series: [extractMapSeries(data, tab, toggle, dataPointMapper)],
    exporting: {
      filename: data.figureNumber,
      sourceHeight: 567,
      sourceWidth: 1024,
      chartOptions: {
        title: {
          text: data.figureNumber + " " + data.title,
          align: "left",
          widthAdjust: -100,
        },
        subtitle: {
          text: data.panel
            ? data.panel.subtitle
              ? data.panel.subtitle
              : data.panel.panel
            : tab,
        },
        caption: {
          text: `Data Source: ${getYear()} United States Renal Data System Annual Data Report`,
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };

  mapOptions = mergeFigureJson(mapOptions, data, {
    tab,
    toggle,
    panel: data.panel?.panel,
  });
  delete mapOptions.xAxis;

  return (
    <div>
      <HighchartsReact
        constructorType={"mapChart"}
        highcharts={Highcharts}
        options={mapOptions}
      />
    </div>
  );
};

const mapTooltopFormats = {
  // eslint-disable-next-line
  ["0.001"]: 3,
  // eslint-disable-next-line
  ["0.01"]: 2,
  // eslint-disable-next-line
  ["0.1"]: 1,
  // eslint-disable-next-line
  ["1"]: 0,
};

export default StateMap;

import React from 'react'
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import Header from './graphComponents/Header'
import Summary from './graphComponents/Summary'
import ErrorFallback from "../../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import config from "../../../../config/config";


import '../tables/DataTable.scss'
const Image = ({ serverdata, size, slug }) => {
    const { year } = useParams();
    const imageData = () => {
        let volume = serverdata.volume === 'chronic-kidney-disease' ? "CKD" :
             serverdata.volume === 'end-stage-renal-disease' ? "ESRD" :
             "Supplements";

        let figureName = serverdata.figureNumber.replace(" ", "").replace(".", "");
        const ImageUrl = `${config.baseUri}/FigureImages?year=${year}&volume=${volume}&figure=${figureName}`;

        return (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <center>
                    <img src={ImageUrl} alt={figureName}/>
                </center>
            </ErrorBoundary>
        );
    }

    return (
        <>
            <Header figureData={serverdata} slug={slug} />
            <div className="py-4">
                {imageData()}
            </div>
            <div className="data-source" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(serverdata.methods, { ADD_ATTR: ['target'] }) }}>
            </div>
            <Summary figureData={serverdata} />
        </>
    )
}

export default Image

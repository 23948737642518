import { RESPONSIVE_BREAK } from "../../../../../utils/constants";
import { nonNullMerge } from "./customMerge";
import mixinCommands from "./mixinCommands";

const panelMixins = (initialOptions, data, json, { tab, toggle, panel }) => {
  if (json?.PANEL) {
    if (json.PANEL[panel]) {
      initialOptions = nonNullMerge(initialOptions, json.PANEL[panel]);
      // TODO: temporary hack
      if (json.PANEL[panel].series) {
        initialOptions.series = json.PANEL[panel].series;
      }

      if (window.innerWidth < RESPONSIVE_BREAK && json.PANEL[panel].MOBILE) {
        initialOptions = nonNullMerge(initialOptions, json.PANEL[panel].MOBILE);
      }

      initialOptions = mixinCommands(initialOptions, data, json.PANEL[panel]);
    } else if (json.PANEL.default) {
      initialOptions = nonNullMerge(initialOptions, json.PANEL.default);

      initialOptions = mixinCommands(initialOptions, data, json.PANEL.default);
    }
  }
  return initialOptions;
};

export default panelMixins;

import React from 'react';

import Chapter from './Chapter';
import './Content.scss';
import Nav from '../nav/Nav';

const Content = () => {
    return (
        <div className="main-layout">
            <Nav />
            <Chapter />

        </div>
    );
}

export default Content
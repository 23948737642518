import React from "react";

const TogglePane = ({
  figureData,
  toggleValues,
  toggleDataSet,
  toggle,
  size,
}) => {
  return (
    <div className="toggle-section">
      {toggleValues ? (
        toggleValues.map((tv) => (
          <label key={tv}>
            <input
              type="radio"
              name={`toggle${figureData.figureNumber}${size}`}
              value={tv}
              checked={toggle === tv}
              onChange={() => toggleDataSet(tv)}
            />
            {tv}
          </label>
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default TogglePane;

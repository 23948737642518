import React from 'react';

function LoadingTable (){
    return (
        <div>
            Loading table...
        </div>
    )
}

export default LoadingTable;
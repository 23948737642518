import React, { Suspense, lazy } from 'react'
import { useParams } from "react-router-dom";
import DOMPurify from 'dompurify';
import Header from './graphComponents/Header'
import Summary from './graphComponents/Summary'
import LoadingTable from '../tables/LoadingTable';
import ErrorFallback from "../../ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

import '../tables/DataTable.scss'
const Table = ({ serverdata, size, slug }) => {
    const { year } = useParams();
    const tableData = () => {
        let volume = serverdata.volume === 'chronic-kidney-disease' ? "CKD" : "ESRD";
        let tableName = serverdata.figureNumber.replace(" ", "").replace(".", "");
        const LazyTable = lazy(() => import(`../tables/${year}/${volume}/${tableName}`));
       
        return (
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Suspense fallback={<LoadingTable />}>
                    <LazyTable />
                </Suspense>
            </ErrorBoundary>
        );
    }

    return (
        <>
            <Header figureData={serverdata} slug={slug} />
            <div className="pt-4">
                {tableData()}
            </div>
            <div className="data-source" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(serverdata.methods, { ADD_ATTR: ['target'] }) }}>
            </div>
            <Summary figureData={serverdata} />
        </>
    )
}

export default Table
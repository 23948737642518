import React from "react";
import Highcharts from "./HighchartsMixins";
import HighchartsReact from "highcharts-react-official";
import worldData from "./maps/worldmap.json";
import initOptions from "./initialToggleOptions";
import "./WorldMap.scss";
import { mergeFigureJson } from "./mixins/mixins";
import * as chartConfig from "../../../../utils/highchartsConfig";
import getYear from "../../../../utils/getYear";

const WorldMap = ({ data, tab }) => {
  const mappedSeries = data.none.values.series
    .flatMap((x) =>
      x.data.map((dp) => {
        return {
          value: dp,
          category: x.name,
        };
      })
    )
    .map((val, idx) => ({
      value: val.value,
      category: val.category,
      country: data.none.values.xAxis.categories[idx],
    }))
    .reduce((acc, item, idx) => {
      const currentArr = acc.filter((x) => x.name === item.category)[0];
      if (!currentArr) {
        acc.push({
          name: item.category,
          type: "map",
          allAreas: false,
          showInLegend: item.category ? true : false,
          data: [
            {
              value: item.value,
              x: item.value,
              countryName: item.country,
              "hc-key": countryToHcKey[item.country],
            },
          ],
        });
      } else {
        currentArr.data.push({
          value: item.value,
          x: item.value,
          countryName: item.country,
          "hc-key": countryToHcKey[item.country],
        });
      }
      return acc;
    }, []);

  let mapOptions = {
    ...initOptions().style,
    chart: {
      map: worldData,
      type: "map",
      height: 700,
      className: "world-map",
      style: {
        fontFamily: chartConfig.font,
      },
    },
    title: {
      text: " ",
    },
    caption: {
      useHTML: true,
      text: data.toggleType.indexOf("PANEL") > -1 ? "" : data.methods,
    },
    legend: {
      useHTML: true,
      symbolWidth: 0,
      itemMarginBottom: 7,
      labelFormatter: function () {
        return `<span role="button" class="legend-item world-map"><span>${this.name}</span></span>`;
      },
    },
    tooltip: {
      style: {
        fontSize: "14px",
      },
      valueDecimals: mapTooltopFormats[data.dataLabelDecimalPlaces],
      pointFormat: `{point.countryName}: {point.value}`,
    },
    credits: {
      text: "",
    },
    series: mappedSeries,
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: true,
    },
    exporting: {
      filename: data.figureNumber,
      sourceHeight: 567,
      sourceWidth: 1024,
      chartOptions: {
        title: {
          text: data.figureNumber + " " + data.title,
          align: "left",
          widthAdjust: -100,
        },
        caption: {
          text: `Data Source: ${getYear()} United States Renal Data System Annual Data Report`,
        },
      },
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadJPEG",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };
  mapOptions = mergeFigureJson(mapOptions, data, {
    tab: null,
    toggle: null,
    panel: null,
  });
  mapOptions.series.unshift({
    showInLegend: false,
    allAreas: true,
  });

  return (
    <div>
      <HighchartsReact
        constructorType={"mapChart"}
        highcharts={Highcharts}
        options={mapOptions}
      />
    </div>
  );
};

const mapTooltopFormats = {
  // eslint-disable-next-line
  ["0.001"]: 3,
  // eslint-disable-next-line
  ["0.01"]: 2,
  // eslint-disable-next-line
  ["0.1"]: 1,
  // eslint-disable-next-line
  ["1"]: 0,
};

const countryToHcKey = {
  "South Africa": "za",
  Ukraine: "ua",
  Bangladesh: "bd",
  Chile: "cl",
  Colombia: "co",
  Iceland: "is",
  Finland: "fi",
  Kazakhstan: "kz",
  China: "cn",
  Norway: "no",
  Sweden: "se",
  Kuwait: "kw",
  Lithuania: "lt",
  "United Kingdom": "gb",
  "England, Wales & Northern Ireland": "gb",
  "Bosnia and Herzegovina": "ba",
  Albania: "al",
  Italy: "it",
  Qatar: "qa",
  Turkey: "tr",
  Argentina: "ar",
  "Saudi Arabia": "sa",
  France: "fr",
  Uruguay: "uy",
  "Hong Kong": "hk",
  Israel: "il",
  Canada: "ca",
  Brazil: "br",
  Indonesia: "id",
  Portugal: "pt",
  Malaysia: "my",
  Greece: "gr",
  Japan: "jp",
  "Rep. of Korea": "kr",
  "Republic of Korea": "kr",
  Singapore: "sg",
  Thailand: "th",
  "United States": "us",
  Hungary: "hu",
  Taiwan: "tw",
  Mexico: "mx",
  Netherlands: "nl",
  Montenegro: "me",
  Australia: "au",
  "New Zealand": "nz",
  Spain: "es",
  Poland: "pl",
  "Belgium, Dutch speaking": "be",
  "Belgium, French speaking": "be",
  Belgium: "be",
  Romania: "ro",
  "North Macedonia": "mk",
  "Czech Republic": "cz",
  Latvia: "lv",
  Estonia: "ee",
  Serbia: "rs",
  Russia: "ru",
  Switzerland: "ch",
  Denmark: "dk",
  "China, mainland": "cn",
  Austria: "at",
  Mongolia: "mn",
  Belarus: "by",
  "El Salvador": "sv",
  "Brunei Darussalam": "bn",
};

export default WorldMap;

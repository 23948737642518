import createInitialToggleOptions from "../components/content/diagrams/graphs/initialToggleOptions";

export const highchartsBoxPlot = (Highcharts) => {
  (function (H) {
    const lineWidth =
      createInitialToggleOptions()?.plotOptions?.line?.lineWidth || 4;
    // when changing point format for boxplot, values will calculate automatically
    H.seriesTypes.boxplot.prototype.pointArrayMap = [
      "low",
      "q1",
      "median",
      "q3",
      "high",
      "mean",
    ];

    H.seriesTypes.boxplot.prototype.toYData = function (point) {
      return [
        point.low,
        point.q1,
        point.median,
        point.q3,
        point.high,
        point.mean,
      ];
    };

    // draw lines after default drawPoints is called:
    H.wrap(H.seriesTypes.boxplot.prototype, "drawPoints", function (p) {
      p.call(this);
      var chart = this.chart,
        r = chart.renderer,
        x,
        y;

      if (this.userOptions.meanType === "line") {
        var path = [];

        this.points.forEach((p, i) => {
          x = p.plotX;
          y = p.meanPlot;

          if (i === 0) {
            path = ["M", x, y];
          } else if (i === this.points.length - 1) {
            path = path.concat(["L", x, y]);
            if (p.series.meanPath) {
              p.series.meanPath.attr({
                d: path,
              });
            } else {
              p.series.meanPath = r
                .path(path)
                .attr({
                  stroke: p.series.color,
                  "stroke-width": lineWidth,
                })
                .add(p.series.group)
                .toFront();
            }
          } else {
            path = path.concat(["L", x, y]);
          }
        });
      } else if (this.userOptions.meanType === "scatter") {
        // for each point, draw line:
        this.points.forEach(function (p) {
          let width = p.shapeArgs.width / 8;
          x = p.shapeArgs.x + p.shapeArgs.width / 2 - width / 2;
          y = p.meanPlot - width / 2;

          if (p.meanPlotX) {
            // update existing path:
            p.meanPlotX.attr({
              x: x,
              y: y,
              width: width,
              height: width,
            });
          } else {
            // create mean-line for the first time
            p.meanPlotX = r
              .rect(x, y, width, width)
              .attr({
                stroke: p.series.color,
                "stroke-width": lineWidth / 2,
              })
              .add(p.graphic);
          }
        });
      } else {
        this.points.forEach(function (p) {
          if (p.meanPlotX) {
            p.meanPlotX.element.remove();
          }
          if (p.series.meanPath) {
            p.series.meanPath.element.remove();
          }
        });
      }
    });
  })(Highcharts);
};

import React from 'react'
import HighchartsReact from 'highcharts-react-official'

const Graph = ({ highcharts, options, size }) => {

    return (
        <div className={`${size === 'micro' ? 'micro-figure' : ''} highcharts-parent`}>
            <HighchartsReact
                highcharts={highcharts}
                options={options}
            />
        </div>
    )
}

export default Graph
import { set } from "lodash";
import {
  toolTipFormatter,
  yAxisFormatter,
} from "../../../../../utils/numberFormatters";
import { nonNullMerge } from "./customMerge";
import mixinCommands from "./mixinCommands";
import panelMixins from "./panelMixins";
import toggleMixins from "./toggleMixins";

const tabToggleMixins = (
  initialOptions,
  data,
  json,
  { tab, toggle, panel }
) => {
  if (json?.TAB) {
    if (json.TAB[tab]) {
      initialOptions = nonNullMerge(initialOptions, json.TAB[tab]);

      if (json.TAB[tab].REPLACE) {
        set(
          initialOptions,
          json.TAB[tab].REPLACE.path,
          json.TAB[tab].REPLACE.value
        );
      }

      if (json.TAB[tab].YAXISFORMATTER) {
        initialOptions.yAxis.labels.formatter = yAxisFormatter(
          json.TAB[tab].YAXISFORMATTER.decimalPlaces
        );
      }
      if (json.TAB[tab].TOOLTIPFORMATTER) {
        initialOptions.tooltip.formatter = toolTipFormatter(
          json.TAB[tab].TOOLTIPFORMATTER.decimalPlaces,
          data.chartType
        );
      }

      initialOptions = mixinCommands(initialOptions, data, json.TAB[tab]);

      if (json.TAB[tab].TOGGLE)
        initialOptions = toggleMixins(initialOptions, data, json.TAB[tab], {
          tab,
          toggle,
          panel,
        });

      if (json.TAB[tab].PANEL) {
        initialOptions = panelMixins(initialOptions, data, json.TAB[tab], {
          tab,
          toggle,
          panel,
        });
      }
    } else if (json.TAB.default) {
      initialOptions = nonNullMerge(initialOptions, json.TAB.default);

      if (json.TAB.default.YAXISFORMATTER) {
        initialOptions.yAxis.labels.formatter = yAxisFormatter(
          json.TAB.default.YAXISFORMATTER.decimalPlaces
        );
      }
      if (json.TAB.default.TOOLTIPFORMATTER) {
        initialOptions.tooltip.formatter = toolTipFormatter(
          json.TAB.default.TOOLTIPFORMATTER.decimalPlaces,
          data.chartType
        );
      }

      initialOptions = mixinCommands(initialOptions, data, json.TAB.default);

      if (json.TAB.default.TOGGLE)
        initialOptions = toggleMixins(initialOptions, data, json.TAB.default, {
          tab,
          toggle,
          panel,
        });

      if (json.TAB.default.PANEL) {
        initialOptions = panelMixins(initialOptions, data, json.TAB.default, {
          tab,
          toggle,
          panel,
        });
      }
    }
  }
  return initialOptions;
};

export default tabToggleMixins;

import React from "react";
import { useQuery } from "@apollo/client";
import { GET_OUTLINE } from "../../queries/outlineData";
import { Nav, Navbar } from "react-bootstrap";
import NIDDKLogo from './NIDDK_USRDS_logo.svg'
import "./MobileNav.scss";

const MobileNav = () => {
  const { data, loading, error } = useQuery(GET_OUTLINE);

  // TODO: Redirect instead of picking first section: ask about home, volume, chapter, section landing pages

  return data && data.volumes ? (
    <Navbar
      aria-label="Site Navigation"
      id="main-mobile-nav"
      expand="xl"
      collapseOnSelect={true}
    >
      {loading ? <div className="informational">Loading...</div> : <></>}
      {error ? (
        <div className="informational">
          An error occured retriving the doucment outline
        </div>
      ) : (
        <></>
      )}
      {/* <MobileNavContent volumes={data && data.volumes} year={year} volume={volume} chapter={chapter} /> */}
      <Navbar.Brand href="/">
        <img src={NIDDKLogo} className="mobile-logo-img" alt="Home Page" />
      </Navbar.Brand>

      <Navbar.Toggle
        aria-controls="mobile-menu-navbar-nav"
        className="toggle-broad-menu toggle-style">
        <span className="toggle-icon" />
        <span className="menutext">
          Menu
        </span>
      </Navbar.Toggle>
      <Navbar.Collapse id="mobile-menu-navbar-nav" className="mobile-menu">
        <Nav className="">
          <Nav.Item>
            <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds">USRDS Home</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/annual-data-report">Annual Data Report</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/prior-data-reports">Prior Data Reports</Nav.Link>
          </Nav.Item>

          <Nav.Item className="inner-dropdown">
            <Nav.Item className="inner-dropdown-link">
              <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers">For Researchers</Nav.Link>
            </Nav.Item>
            <span className="inner-dropdown-border"/>
            <Navbar
              aria-label="For Researchers"
              id="for-researchers-nav"
              expand="xl"
              collapseOnSelect={true}
            >
              <Navbar.Toggle
                aria-controls="for-researchers"
                className="toggle-broad-menu toggle-style">
                <span className="inner-dropdown-nav"/>
              </Navbar.Toggle>
              <Navbar.Collapse id="for-researchers" className="mobile-menu inner-menu">
                <Nav>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/simple-data-requests">Simple Data Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/standard-analysis-files">Standard Analysis Files</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/merged-data-requests">Merged Data Requests</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/researchers-guide">Researcher's Guide</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/esrd-quarterly-update">ESRD Quarterly Update</Nav.Link>
          </Nav.Item>

          <Nav.Item className="inner-dropdown">
            <Nav.Item className="inner-dropdown-link">
              <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools">Data Query Tools</Nav.Link>
            </Nav.Item>
            <span className="inner-dropdown-border"/>
            <Navbar
              aria-label="Data Query Tools"
              id="data-query-tools-nav"
              expand="xl"
              collapseOnSelect={true}
            >
              <Navbar.Toggle
                aria-controls="data-query-tools"
                className="toggle-broad-menu toggle-style">
                <span className="inner-dropdown-nav"/>
              </Navbar.Toggle>
              <Navbar.Collapse id="data-query-tools" className="mobile-menu inner-menu">
                <Nav>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-incident-count">ESRD Incident Count</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-prevalent-count">ESRD Prevalent Count</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-mortality-rate">ESRD Mortality Rate</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-hospitalization-rate">ESRD Hospitalization Rate</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Nav.Item>

          <Nav.Item className="inner-dropdown">
            <Nav.Item className="inner-dropdown-link">
              <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/news">USRDS News</Nav.Link>
            </Nav.Item>
            <span className="inner-dropdown-border"/>
            <Navbar
              aria-label="USRDS News"
              id="usrds-news-nav"
              expand="xl"
              collapseOnSelect={true}
            >
              <Navbar.Toggle
                aria-controls="usrds-news"
                className="toggle-broad-menu toggle-style">
                <span className="inner-dropdown-nav"/>
              </Navbar.Toggle>
              <Navbar.Collapse id="usrds-news" className="mobile-menu inner-menu">
                <Nav>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/news/publication-spotlights">Publication Spotlights</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Nav.Item>

          <Nav.Item className="inner-dropdown">
            <Nav.Item className="inner-dropdown-link">
              <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/about-usrds">About USRDS</Nav.Link>
            </Nav.Item>
            <span className="inner-dropdown-border"/>
            <Navbar
              aria-label="About USRDS"
              id="about-usrds-nav"
              expand="xl"
              collapseOnSelect={true}
            >
              <Navbar.Toggle
                aria-controls="about-usrds"
                className="toggle-broad-menu toggle-style">
                <span className="inner-dropdown-nav"/>
              </Navbar.Toggle>
              <Navbar.Collapse id="about-usrds" className="mobile-menu inner-menu">
                <Nav>
                  <Nav.Item>
                    <Nav.Link href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/about-usrds/publications">Publications</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Nav.Item>

        </Nav>
      </Navbar.Collapse>
    </Navbar>
  ) : (
    <></>
  );
};

export default MobileNav;

import React from "react";

const Introduction_2022 = () => {
  return (
    <>
      <h1>Introduction</h1>
      <section>
                 <p>
          The United States Renal Data System 2022 Annual Data Report (ADR) contains data 
          from medical claims through 2020 and for some ESRD-related metrics through the first 
          half of 2021. As such, this is the first year in which the wide-ranging effects of 
          the coronavirus disease 2019 (COVID-19) pandemic on the CKD and ESRD populations 
          can be placed into the full context of the years that preceded its onset. Another 
          important aspect of this year’s ADR is a continued focus on racial, ethnic, and 
          socioeconomic disparities in access to healthcare and outcomes. Unfortunately, these 
          two topics intersect in the form of racial and ethnic disparities in vulnerability 
          and susceptibility to COVID-19 that further exposed and exacerbated pre-existing 
          disparities in incidence, prevalence, and care of patients with kidney disease.
                  </p>
                  <h5>
          Direct and indirect effects of COVID-19 on the CKD and ESRD populations
                  </h5>
                  <p>
          The devastating effects of the COVID-19 pandemic on the CKD and ESRD populations 
          permeate the 2022 ADR. The 2022 ADR includes data on medical care and outcomes 
          through calendar year 2020 (although some data for the first half of 2021 for the 
          ESRD population are included). Although we presented early views into the impact 
          of the COVID-19 pandemic on the ESRD population in the 2020 ADR and expanded these 
          analyses to include examination of COVID-19 diagnoses and outcomes in the CKD 
          population in the 2021 ADR, the full magnitude of the direct and indirect effects 
          of the pandemic on these populations comes into sharp focus throughout this year’s 
          report.
                  </p>
                  <p>
          The direct effects of COVID-19 can be measured by examining patterns of patient 
          testing, hospitalization, and mortality. Over 10% of patients with CKD, 13% of 
          patients with a kidney transplant, and 20% of patients on dialysis in January of 
          2020 were diagnosed with COVID-19 by the end of June 2021, rates that were 
          approximately 50%, 100%, and 200% higher than that of Medicare beneficiaries 
          without CKD, respectively. Although the incidence of COVID-19 testing was higher 
          among individuals with CKD, the incidence of hospitalization after COVID-19 
          diagnosis among patients with CKD was more than double that of patients those 
          without CKD in 2020; patients receiving dialysis consistently had hospitalization 
          rates higher still than those with CKD. Mortality at 14, 30, and 90 days after 
          diagnosis of COVID-19 was more than twice as high among beneficiaries with CKD 
          as among those without. Nearly one-quarter of patients with CKD who were diagnosed 
          with COVID-19 died within 90 days. Mortality after COVID-19 diagnosis was even 
          higher for patients with ESRD, reaching 40.5% for patients on dialysis and 44.1% 
          among kidney transplant recipients 90 days after diagnosis. 
                  </p>
                  <p>
          The ultimate result of the higher incidence of COVID-19 and higher mortality after 
          diagnosis of COVID-19 among patients with CKD and ESRD was the unprecedented shrinking 
          of the prevalence of diagnosed CKD (CKD Volume, Figure 2.1) and ESRD (ESRD Volume, 
          Figure 1.5) in 2020. For example, the absolute increase in mortality in 2020 among 
          patients with CKD was 8.9 deaths per 1,000 person-years compared with 6.1 per 1,000 
          person-years among individuals without CKD (Figure 3.2a). By the end of 2020, the 
          population with diagnosed CKD had decreased by 4.3% from 2019. Some of the decline 
          in the CKD population in 2020 may have been an artifact of fewer opportunities to 
          accrue CKD diagnoses given the lower rates of hospitalization that occurred in 2020, 
          combined with a general reduction in overall interaction with the healthcare system 
          that occurred across the U.S. However, this is not true of the ESRD population, whose 
          tracking through EQRS and OPTN does not rely on diagnosis claims. As a result of fewer 
          patients reaching diagnosed ESRD and the increase in mortality rate among patients with 
          ESRD attributable to the pandemic and its effects, the rate of prevalent ESRD decreased 
          by almost 2% in 2020.
                  </p>
                  <p>
          Indirect pandemic effects may have also affected patients with CKD to a greater extent 
          than patients without kidney disease. Rates of emergency department encounters and 
          hospitalizations decreased substantially in 2020 among patients with and without CKD 
          and ESRD. However, the negative consequences of forgoing this medical care may have 
          led to a disproportionate increase in mortality among patients with CKD because of 
          their higher comorbidity burden and higher a priori risk of adverse outcomes. For 
          example, mortality increased more in 2020 among patients with stage 4 and 5 CKD than 
          among patients with stage 3 CKD. 
                  </p>
                  <p>
          Mortality after COVID-19 was higher among Black and Hispanic Medicare beneficiaries 
          with CKD than among White beneficiaries (Figure 13.5). As a direct result of this 
          higher COVID-19-related mortality and, possibly, more limited access to non-COVID-19-related 
          medical care, mortality increased more among Black than among White beneficiaries with 
          stage 4 and 5 CKD in 2020. This resulted in a reversal of the longstanding observation 
          of lower mortality among Black patients. In other words, whereas Black beneficiaries had 
          lower mortality than White ones in 2019 and prior years, they had higher mortality than 
          their White counterparts in 2020 (Figure 3.2b). Although unadjusted mortality was not 
          higher among Black than among White patients with ESRD, rates of COVID-19 diagnosis were 
          higher (Figure 13.2b). A similar reversal of the Black-White mortality difference occurred 
          in transplant recipients: mortality was higher among White recipients in 2019 but among 
          Black recipients in 2020 (Figure 6.5b). The mortality difference did not reverse among 
          patients treated with dialysis, but it did narrow from 43% higher mortality among White 
          patients in 2019 to only 30% higher mortality in 2020.
                  </p>
                  <p>
          Other direct and indirect effects of COVID-19 and the changes in availability and delivery 
          of healthcare that occurred in 2020 can be seen throughout the ADR and in many metrics 
          typically tracked in the CKD and ESRD populations.
                  </p>
          
                  <section>
          Examples among patients with CKD include:
                    <section style={{ marginTop: "1rem" }}>
                      <ul>
                        <li>
          Among patients with CKD, there was a particularly steep reduction in the rate of all-cause 
          hospitalization in 2020 (14.9%); this single-year decrease was larger than the cumulative 
          change over the previous 6-year period from 2013 to 2019 (Figure 3.4). Rates of emergency 
          department encounters were also considerably lower in 2020 (Figure 3.15d).
                        </li>
                        <li>
          Although rates of readmission within 30 days of hospital discharge remained stable in 2020 
          among patients with CKD, the rate of death within 30 days of hospital discharge (without 
          rehospitalization) increased by almost 20% (Figures 3.13, 3.14d). 
                        </li>
                        <li>
          Although the overall rate of hospitalization with acute kidney injury (AKI) decreased in 
          2020 (likely related to the overall reduction in hospitalization rate; Figure 4.1), AKI 
          events worsened, as indicated by a 16% increase in the need for dialysis among those with 
          AKI (Figure 4.2). This increase occurred after a sustained period of decreasing need for 
          dialysis during hospitalization with AKI.
                        </li>
                        <li>
          Outcomes after AKI requiring dialysis (AKI-D) during a COVID-19 hospitalization in 2020-2021 
          were substantially worse than outcomes after AKI-D without COVID-19: almost three-quarters 
          (74.1%) of those with COVID-19 and AKI-D died during hospitalization or were discharged to 
          hospice care compared with 35.2% of those without COVID-19 (Figure 4.4c). 
                        </li>
                        <li>
          Inflation-adjusted overall Medicare fee-for-service (FFS) spending for older (≥66 years) 
          beneficiaries with nondialysis-dependent CKD decreased by almost 3% in 2020, or by ~$2.2B 
          (Figure 6.3). Inpatient spending decreased by 4%, which was a combination of a ~$2.4B 
          decrease in spending on non-COVID-19 hospitalization plus approximately $1.4B in spending 
          for COVID-19 hospitalization (Figure 6.4).
                        </li>
                        <li>
          Hydroxychloroquine and chloroquine use among older Medicare Part D beneficiaries with CKD 
          spiked in March and April 2020 (Figure 7.13), at a time when rates of receipt of many common 
          drugs used to treat hypertension or CKD transiently declined (Figure 7.10). Rates of 
          ivermectin use increased dramatically in November and December of 2020 among older Medicare 
          Part D beneficiaries with CKD (Figure 7.14).
                        </li>
                      </ul>
                    </section>
                  </section>
          
                  <section>
          Additional examples of direct or indirect effects of COVID-19 on patients with ESRD are 
          also numerous:
                    <section style={{ marginTop: "1rem" }}>
                      <ul>
                        <li>
          The percentage of patients with incident ESRD in 2020 starting in-center HD decreased, and 
          the corresponding percentage starting PD increased (Figure 1.2).
                        </li>
                        <li>
          The percentage of older Medicare beneficiaries with ESRD and diabetes who received preventive 
          care, including glycated hemoglobin (A1c) testing, lipid testing and eye examination decreased 
          in 2020 (Figure 3.15). Only about 25.6% of patients received all three of these examinations, 
          compared with 31.4% in 2019.
                        </li>
                        <li>
          The percentage of patients initiating HD with a catheter increased in 2020 to 71.2%, and the 
          corresponding percentage initiating with an AVF decreased to 25% overall (including AVFs that 
          were maturing or were in use, or 14.1% for AVFs used at dialysis initiation; Figure 4.1).
                        </li>
                        <li>
          The number of patients with ESRD newly added to the kidney transplant waitlist in 2020 decreased 
          by 12% (Figure 7.1). There was a corresponding decrease in the total number and of patients with 
          ESRD on the waitlist that was particularly pronounced for those listed with active status (Figure 
          7.2). The percentage of dialysis patients on the kidney transplant waitlist also declined in 2020 
          (Figure 7.3).
                        </li>
                        <li>
          The rate of receipt of living donor kidney transplants among patients on dialysis decreased by 
          27.3% in 2020 (Figure 7.10a).
                        </li>
                        <li>
          Adjusted 1-year post-kidney transplant patient and allograft survival decreased in 2020 among 
          recipients of living and deceased donor kidney transplants (Figures 7.19a, 7.20a).
                        </li>
                        <li>
          The number of children with incident ESRD decreased in 2020, driven primarily by a reduction in 
          the number who received a preemptive kidney transplant (Figures 8.1, 8.3b).
                        </li>
                        <li>
          The rates of kidney transplantation among children receiving dialysis decreased by 6% in 2020 
          (Figure 8.13).
                        </li>
                        <li>
          Total Medicare spending for beneficiaries with ESRD decreased by $2.2B in 2020 (Figure 9.1). 
          Medicare FFS spending for ESRD beneficiaries as a percentage of total FFS spending decreased to 
          6.1% in 2020 after 10 years at 7.1-7.2% (Figure 9.3). The biggest (inflation-adjusted) decrease 
          in FFS spending for ESRD was for outpatient spending (-$1B), and inpatient spending decreased by 
          $0.6B (Figure 9.5). 
                        </li>
                        <li>
          Hydroxychloroquine and chloroquine use among Medicare Part D beneficiaries with ESRD spiked in 
          March and April 2020 (Figure 10.13). Rates of ivermectin use increased dramatically in November 
          and December of 2020 among Medicare Part D beneficiaries with ESRD (Figure 10.14).
                        </li>
                      </ul>
                    </section>
                  </section>
          
                  <h5>
          Racial, ethnic, and socioeconomic disparities
                  </h5>
          
                  <p>
          In the 2021 ADR, we did not observe disparities in rates of outpatient nephrology visits or 
          receipt of medications to treat CKD or its complications, including angiotensin converting 
          enzyme inhibitors or angiotensin receptor blockers, oral potassium or phosphorus binders, or 
          sodium-glucose cotransporter-2 inhibitors, by race/ethnicity. Rates of nephrology encounters 
          also differed little by level of neighborhood deprivation. These results suggest that Medicare 
          coverage, including Part D and the Low Income Subsidy, appeared to provide comparable access to 
          care for CKD across race/ethnicity groups and across levels of neighborhood deprivation. We 
          hypothesized that barriers to access to care prior to Medicare eligibility likely contribute to 
          the higher rates and earlier onset of diabetes and hypertension among Black and Hispanic individuals 
          as well as to the higher risk of subsequent CKD and ESRD. To address this question, this year’s 
          ADR includes data on younger Medicaid beneficiaries aged 18 to 64 years, in which we examined access 
          to medications and nephrology care in these younger patients. We again found little disparity by 
          race/ethnicity or by neighborhood in receipt of medications or nephrology encounters. However, rates 
          of nephrology encounters among younger Medicare beneficiaries were less than half those among older 
          Medicare beneficiaries. Thus, the younger, more heavily Black, Hispanic, and lower socioeconomic 
          status (SES) Medicaid population appeared to have considerably less access to nephrology care. 
          Medicaid coverage may provide less access to nephrology care than Medicare coverage, or insurance 
          coverage may be insufficient to overcome barriers to accessing care experienced by younger patients 
          with low SES, such as transportation or concerns about loss of work income. Furthermore, limitations 
          in access to Medicaid (e.g., across U.S. states) likely introduce further disparities that cannot be 
          examined using medical claims, as uninsured patients almost certainly have more limited access to care. 
          Further examination of these issues using more detailed data sources will be critical to developing and 
          implementing strategies to address healthcare disparities.
                  </p>
      </section>
    </>
  );
};

export default Introduction_2022;
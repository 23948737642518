import React, { useState } from "react";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import Octicon, { ChevronRight } from "@primer/octicons-react";
import { useQuery } from "@apollo/client";
import { GET_OUTLINE } from "../../queries/outlineData";
import ChapterNavContent from "./ChapterNavContent";
import { isContentChapter } from "../../utils/chapterFormat";
import { GoogleSearch } from "./GoogleSearch";
import isHiddenChapter, { hiddenVolumeStyle } from "../../utils/hiddenChapter";

import "./Nav.scss";

const Nav = () => {
  const { data, loading, error } = useQuery(GET_OUTLINE);

  let {
    params: { year, volume, chapter },
  } = useRouteMatch("/:year/:volume?/:chapter?");
  const activeVolume = volume;
  const history = useHistory();
  const [showSections, setShowSections] = useState(true);

  const activateVolume = (volume) => {
    const currentVol = `/${year}/${volume.slug}`;
    history.push(currentVol);
  };

  const onSetSectionView = (chapSlug) => {
    if (chapSlug === chapter) {
      setShowSections(!showSections);
    } else {
      setShowSections(true);
    }
  };

  return (
    <nav className="nav-container">
      {loading ? (
        <div className="informational">
          Getting volume and chapter information...
        </div>
      ) : (
        <></>
      )}
      {error ? (
        <div className="informational">
          There was an error retriving outline (volume and chapter) information
          from the server
        </div>
      ) : (
        <></>
      )}
      {data ? (
        <>
          <div className="report-title">
            <Link to={`/${year}`}>{year} Annual Report</Link>
          </div>
          <div>
            <GoogleSearch/>
          </div>
          <ul className="nav main-navigation flex-column">
            {data && data.volumes ? (
              data.volumes.map((vol) => {
                return (
                  <li
                    className={`nav-item volume ${
                      activeVolume === vol.slug ? "active" : ""
                    } ${
                      vol.chapters && vol.chapters.length
                        ? "chapters"
                        : "no-chapter"
                    }`}
                    key={vol.slug}
                  >
                    <div
                      className={`volume-title ${
                        activeVolume === vol.slug ? "active" : ""
                      } ${
                        vol.chapters && vol.chapters.length
                          ? "chapters"
                          : "no-chapter"
                      }`}
                      onClick={() => activateVolume(vol)}
                    >
                      <div
                        className={`active-title ${
                          vol.chapters && vol.chapters.length
                            ? ""
                            : "no-chapter"
                        } ${hiddenVolumeStyle(vol)}`}
                      >
                        {vol.title}
                      </div>
                      <div className="active-icon">
                        <Octicon icon={ChevronRight} />
                      </div>
                    </div>
                    <ul
                      className={`chapter-list-nav ${
                        activeVolume === vol.slug ? "" : "hidden"
                      }`}
                    >
                      {vol.chapters ? (
                        vol.chapters.map((chap) => {
                          return (
                            <li key={chap.slug} className="nav-item-header">
                              <div
                                className={`header-wrapper ${isHiddenChapter(chap, vol) ? "disabledHeader" : ""} ${chap.slug === chapter ? "active" : ""}
                                  `}
                              >
                                <span className="nav-item-header-icon">
                                  <Octicon icon={ChevronRight} />
                                </span>
                                <Link
                                  to={`/${year}/${vol.slug}/${chap.slug}`}
                                  className={`nav-item chapter ${isHiddenChapter(chap, vol) ? "disabledChapter" : ""}`}
                                  onClick={() => onSetSectionView(chap.slug)}
                                >
                                  <div className="nav-item-header-link">
                                    {chap.chapterNumber < 1 ||
                                    !isContentChapter(chap, vol) ? (
                                      ""
                                    ) : (
                                      <span>
                                        {`${chap.chapterNumber}.`}&nbsp;
                                      </span>
                                    )}
                                    <span>{chap.title}</span>
                                  </div>
                                </Link>                                
                              </div>
                              {chap.figures &&
                              chap.slug === chapter &&
                              chap.chapterNumber > 0 &&
                              isContentChapter(chap, vol) && 
                              !isHiddenChapter(chap, vol)
                               ? (
                                <div
                                  className={`figures-wrapper ${
                                    showSections ? "" : "hide-figure-wrapper"
                                  }`}
                                >
                                  <div className="figures">
                                    <ChapterNavContent selectedChapter={chap} />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </ul>
                  </li>
                );
              })
            ) : (
              <></>
            )}
          </ul>
        </>
      ) : (
        <></>
      )}
    </nav>
  );
};

export default Nav;

import React from "react";
import { useQuery } from "@apollo/client";
import { getSectionsByChapterId } from "../../queries/sections";
import { useEffect } from "react";
import SectionWaypoint from "./temp/SectionWaypoint";
import Octicon, { FileSymlinkFile } from "@primer/octicons-react";
import config from "../../config/config";
import { useParams } from "react-router";

export default function ChapterDetails({ selectedChapter, selectedVolume }) {
  const { data: secData } = useQuery(
    getSectionsByChapterId(selectedChapter.id)
  );

  const { year } = useParams();

  useEffect(() => {
    if (window.location.hash && secData) {
      const id = window.location.hash.replace("#", "");
      const elemToScrollTo = document.getElementById(id);
      const main = document.getElementById("main");
      if (elemToScrollTo && main) {
        main.scrollTop = elemToScrollTo.offsetTop;
      }
    }
  }, [secData]);

  const printer = () => {
    const chapNum = selectedChapter.chapterNumber;
    const volNum = selectedVolume.order;
    const url = `${config.baseUri}/PDF?year=${year}&chapterNumber=${chapNum}&volumeNumber=${volNum}`;
    window.location = url;
  };

  return (
    <div>
      <div className="volume-chapter-number">
        <div>
          {selectedVolume.title}: Chapter {selectedChapter.chapterNumber}
        </div>
        <div className="printer" onClick={() => printer()}>
          Download PDF{" "}
          <Octicon className="print-option" icon={FileSymlinkFile} />
        </div>
      </div>
      <h1>{selectedChapter.title}</h1>
      {secData && secData.sections ? (
        secData.sections.map((section, idx) => {
          return (
            <div key={section.id}>
              <SectionWaypoint
                key={section.title}
                section={section}
                first={idx === 0}
              />
              {idx !== secData.sections.length - 1 ? (
                <hr className="horizontal-rule" />
              ) : (
                <></>
              )}
            </div>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
}

import gql from "graphql-tag";

export const GET_OUTLINE = gql`
  {
    volumes {
      slug
      title
      order
      chapters {
        id
        slug
        title
        chapterNumber
        figures {
          figureNumber
          toggleType
          chartType
        }
      }
      text
    }
  }
`;

import React from 'react'

const IntroductionToVolume2 = () => {
    return (
        <>
            <h1>Introduction to Volume 2</h1>
            <section>
                <p>Volume 2 provides data on the incidence and prevalence, characteristics, costs and utilization, and outcomes among individuals with ESRD in the U.S., as well as trends over time in these parameters. The primary sources of information about patients with ESRD are the Centers for Medicare & Medicaid Services (CMS) CROWN/REMIS (Consolidated Renal Operations in Web-Enabled Network/Renal Management Information System), CMS fee-for-service claims data, and the Organ Procurement and Transplantation Network (OPTN). These sources differ from the CMS 5% sample and NHANES data used in Volume 1 of the ADR in that there is a need to combine across multiple data sources. In addition, data on race and ethnicity are collected differently and available in more detail for patients with ESRD.</p>
            </section>
            <h2>Building the ESRD Database</h2>
            <section>
                <p>USRDS assigns a unique identification (USRDS ID) number that allows each patient to be accurately tracked over time in the USRDS database across all associated treatment therapies, hospitalizations, outpatient encounters, and other healthcare events. Linkage across databases and assignment of USRDS IDs must be performed annually, linking new claims and other data to the correct individuals already in the database through comparison of all data sources, identifying new patients, and assigning USRDS IDs. Key patient attributes and identifiers, such as name, date of birth, sex, date of death, SSN, and HIC/BIC (Health Insurance Claim/Beneficiary Identification Code) identifier, have typically been used to uniquely identify each ESRD patient. </p>
                <p>As the process of building the ESRD database is executed, technical personnel analyze each database for duplicate records, consolidate records, and integrate the databases. Small differences, errors, or missing values for patient identifiers complicate this process, and matching rules must therefore be developed and applied. Overly stringent criteria may result in inclusion of some individuals more than once, whereas overly loose criteria risk combing different individuals into one record. Prior matching strategies appear to have been heavily reliant on requiring a SSN or HIC/BIC for inclusion in the database. However, requiring a SSN match results in exclusion of individuals who do not have SSNs and those whose SSN was entered incorrectly. Furthermore, Medicare has recently discontinued use of SSN as its primary form of identification of beneficiaries to better protect beneficiary privacy and financial information. The Medicare Access and CHIP Reauthorization Act of 2015 (MACRA) required CMS to remove social security numbers (SSNs) from all Medicare cards, a process that was completed in April 2019. CMS has now assigned a randomly-generated alphanumeric Medicare Beneficiary Identification (MBIs) that is unrelated to SSN to all beneficiaries. All claims are required to contain this identifier as of January 1, 2020. By design, SSNs will be less reliably included in CMS databases going forward. For these reasons, USRDS has developed a matching process that does not require a SSN (as long as there is sufficient matching of other identifiers).</p>
                <p>This change has identified some additional incident and prevalent cases of ESRD, particularly in recent years, compared with the prior matching process. These newly identified individuals differ from the general ESRD population in that they are younger, are more likely to be Hispanic or Latino, and are concentrated in western states. We believe that a large percentage of these individuals may be undocumented immigrants, especially as many reside in states that reimburse maintenance dialysis through emergency Medicaid or through third-party charitable assistance (Cervantes et al., 2019). Thus, these individuals may have been excluded from the database because of missing SSN and Medicare HIC/BIC but are likely true cases of ESRD. We provide this explanation because discerning readers may note slightly higher ESRD incidence and prevalence counts and rates in this year’s ADR than in prior years, particularly from 2013 to 2017.</p>
            </section>
            <h2>Race and ethnicity</h2>
            <section>
                <p>Information about race in the USRDS comes from several sources, including Form CMS-2728 (ESRD Medical Evidence Report), the CMS Medicare Enrollment Database (EDB), the REMIS patient file, and the CROWNWeb patient roster. The Medical Evidence Report is the primary source of information about race and ethnicity when available. Since the 1995 revision of the Medical Evidence Report, the categories of race recommended in Office of Budget and Management (OMB) guidance (Institute of Medicine, 2009) are included. Specifically, the five race categories are White, Black or African American, American Indian or Alaska Native, Asian, Native Hawaiian or Pacific Islander (and Other was recently added back to the form), with respondents able to designate all that apply. Ethnicity is ascertained separately from race as Hispanic or Latino or not Hispanic or Latino.</p>
                <p>In 2017, the USRDS reported the incidence and prevalence of ESRD for individuals in the Native Hawaiian or Pacific Islander race group separately (from Asians) for the first time (Saran et al., 2018). The extraordinarily high rates in this population caused concern (Naʻai & Raphael, 2019) and spurred further evaluation (Xiang et al., 2020), which has identified considerable uncertainty about the true rates in this population. Differences in availability of information about multi-racial individuals in the USRDS and the U.S. Census are a major source of this uncertainty. Over half of individuals who identify as Native Hawaiian or Pacific Islander (NHPI) in the U.S. Census also selected at least one other race category (Centers for Disease Control and Prevention, 2019). Thus, rate calculations that include only individuals designated solely as NHPI in the U.S. Census in the denominator will overestimate the incidence and prevalence of ESRD in this population.</p>
                <p>However, including all individuals who designate NHPI in the denominator (including multiracial individuals) will almost certainly underestimate the prevalence because the USRDS (effectively) includes only one race. Although the Medical Evidence Report allows individuals to choose more than one race, fewer than 1% of individuals have more than one race category designated in the USRDS, and no one who started dialysis after 2012 has more than one category. Thus, the single race category in the USRDS is unlikely to be capturing all NHPI who self-report more than one race. Because there is no way to ascertain the true rates of ESRD in the NHPI population at present, and because the possible range is extremely wide, we do not report incidence and prevalence of ESRD for this race group in the text of the 2020 ADR.</p>
                <p>We calculated incidence and prevalence rates for other race groups using all individuals in the U.S. Census who designated that race (including multi-racial individuals) in the denominator for each group. This strategy results in a small decrease in rates because few individuals in these race groups designate more than one race. The problem of incomplete data on race in the USRDS is one that needs further exploration as the number of multiracial individuals in the U.S is expected to increase over time. </p>
                <p>We have treated ethnicity separately from race in the Volume 2 of the ADR rather than creating combined race and ethnicity categories. (Race and ethnicity were combined in some analyses in Volume 1 because they are not reported separately in the Medicare 5% sample.) In recognition of the overlapping but non-identical populations included in Hispanic and Latino groups, we have used both terms in the text of the ADR (Hispanic or Latino), but we have continued to use Hispanic in tables and figures in this year’s ADR to avoid the need to use non-standard abbreviations.</p>
            </section>
            <h3 className="section-references">References</h3>
            <ul className="section-references-list">
                <li>Centers for Disease Control and Prevention. (2019). U.S. Census populations with bridged race categories. Retrieved August 4, 2020 from <a href="https://www.cdc.gov/nchs/nvss/bridged_race.htm" target="_blank" rel="noopener noreferrer">https://www.cdc.gov/nchs/nvss/bridged_race.htm</a></li>
                <li>Cervantes, L., Mundo, W., &amp; Powe, N. R. (2019, Aug 7). The Status of Provision of Standard Outpatient Dialysis for US Undocumented Immigrants with ESKD. Clin J Am Soc Nephrol, 14(8), 1258-1260. <a href="https://doi.org/10.2215/cjn.03460319" target="_blank" rel="noopener noreferrer">https://doi.org/10.2215/cjn.03460319</a></li>
                <li>IOM (Institute of Medicine). (2009). Race, ethnicity, and language data: standardization for health care quality improvement. T. N. A. Press. </li>
                <li>Naʻai, D., &amp; Raphael, K. L. (2019, Nov 7). CKD in Native Hawaiians and Pacific Islanders: Trouble in Paradise. Clin J Am Soc Nephrol, 14(11), 1661-1663. <a href="https://doi.org/10.2215/cjn.03260319" target="_blank" rel="noopener noreferrer">https://doi.org/10.2215/cjn.03260319</a></li>
                <li>Saran, R., Robinson, B., Abbott, K. C., Agodoa, L. Y. C., Bhave, N., Bragg-Gresham, J., Balkrishnan, R., Dietrich, X., Eckard, A., Eggers, P. W., Gaipov, A., Gillen, D., Gipson, D., Hailpern, S. M., Hall, Y. N., Han, Y., He, K., Herman, W., Heung, M., Hirth, R. A., Hutton, D., Jacobsen, S. J., Jin, Y., Kalantar-Zadeh, K., Kapke, A., Kovesdy, C. P., Lavallee, D., Leslie, J., McCullough, K., Modi, Z., Molnar, M. Z., Montez-Rath, M., Moradi, H., Morgenstern, H., Mukhopadhyay, P., Nallamothu, B., Nguyen, D. V., Norris, K. C., O'Hare, A. M., Obi, Y., Park, C., Pearson, J., Pisoni, R., Potukuchi, P. K., Rao, P., Repeck, K., Rhee, C. M., Schrager, J., Schaubel, D. E., Selewski, D. T., Shaw, S. F., Shi, J. M., Shieu, M., Sim, J. J., Soohoo, M., Steffick, D., Streja, E., Sumida, K., Tamura, M. K., Tilea, A., Tong, L., Wang, D., Wang, M., Woodside, K. J., Xin, X., Yin, M., You, A. S., Zhou, H., &amp; Shahinian, V. (2018, Mar). US Renal Data System 2017 Annual Data Report: Epidemiology of Kidney Disease in the United States. Am J Kidney Dis, 71(3 Suppl 1), A7. <a href="https://doi.org/10.1053/j.ajkd.2018.01.002" target="_blank" rel="noopener noreferrer">https://doi.org/10.1053/j.ajkd.2018.01.002</a></li>
                <li>Xiang, J., Morgenstern, H., Li, Y., Steffick, D., Bragg-Gresham, J., Panapasa, S., Raphael, K. L., Robinson, B. M., Herman, W. H., &amp; Saran, R. (2020, May 5). Incidence of ESKD Among Native Hawaiians and Pacific Islanders Living in the 50 US States and Pacific Island Territories. Am J Kidney Dis. <a href="https://doi.org/10.1053/j.ajkd.2020.01.008" target="_blank" rel="noopener noreferrer">https://doi.org/10.1053/j.ajkd.2020.01.008</a></li>
            </ul>
        </>
    )
}

export default IntroductionToVolume2
import gql from "graphql-tag";
import { figureMetadata, highchartsConfig } from "./figureDataFragments";

export const getFigureQuery = (figureNumber, chapterId, figureType) => {
  let chartFragment = highchartsConfig;

  switch (figureType) {
    case "TOGGLE only":
      return getToggleFigureQuery(figureNumber, chapterId, chartFragment);
    case "TOGGLE ONLY":
      return getToggleFigureQuery(figureNumber, chapterId, chartFragment);
    case "TAB AND TOGGLE":
      return getTabToggleFigureQuery(figureNumber, chapterId, chartFragment);
    case "TOGGLE AND PANEL":
      return getTogglePanelQuery(figureNumber, chapterId, chartFragment);
    case "TAB AND TOGGLE AND PANEL":
      return getTabTogglePanelQuery(figureNumber, chapterId, chartFragment);
    case "PANEL ONLY":
      return getPanelFigureQuery(figureNumber, chapterId, chartFragment);
    case "TAB AND PANEL":
      return getTabPanelQuery(figureNumber, chapterId, chartFragment);
    case "NONE":
      return getNoneQuery(figureNumber, chapterId, chartFragment);
    case "TAB ONLY":
      return getTabFigureQuery(figureNumber, chapterId, chartFragment);
    case "TABLE":
      return getTableQuery(figureNumber, chapterId, chartFragment);
    case "IMAGE":
      return getTableQuery(figureNumber, chapterId, chartFragment);
    default:
      throw new Error("Could not determine figure type");
  }
};

export const getTogglePanelQuery = (figureNumber, chapterId, chartFragment) => {
  return gql`
    ${figureMetadata}
    ${chartFragment}
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            ...figureMetadata
            togglePanel {
                toggle
                values {
                    panel
                    subtitle
                    xAxis
                    xAxis1
                    xAxis2
                    values {
                        ...highchartsConfig
                    }
                }
            }
        }
    }
    `;
};

export const getTabTogglePanelQuery = (
  figureNumber,
  chapterId,
  chartFragment
) => {
  return gql`
    ${figureMetadata}
    ${chartFragment}
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            ...figureMetadata
            tabTogglePanel{
                tab
                values {
                    toggle
                    values {
                        panel
                        subtitle
                        xAxis
                        xAxis1
                        xAxis2
                        values {
                            ...highchartsConfig
                        }
                    }
                }
            }
        }
    }`;
};

export const getTabFigureQuery = (figureNumber, chapterId, chartFragment) => {
  return gql`
    ${figureMetadata}
    ${chartFragment}
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            ...figureMetadata
            tabOnly {
                tab      
                xAxis
                xAxis1
                xAxis2
                values {
                    ...highchartsConfig
                }
            }
        }
    }
    `;
};

export const getNoneQuery = (figureNumber, chapterId, chartFragment) => {
  return gql`
    ${figureMetadata}
    ${chartFragment}
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            ...figureMetadata
            none {
                values {
                    ...highchartsConfig
                }
            }
        }
    }
    `;
};

export const getTabPanelQuery = (figureNumber, chapterId, chartFragment) => {
  return gql`
            ${figureMetadata}
            ${chartFragment}
            {
            figure(id: "${figureNumber}", chapterId: ${chapterId}) {
                ...figureMetadata
                tabPanel {
                    tab
                    values {
                        panel
                        xAxis
                        xAxis1
                        xAxis2
                        subtitle
                        values {
                            ...highchartsConfig
                        }
                    }
                }
            }
        }`;
};

export const getPanelFigureQuery = (figureNumber, chapterId, chartFragment) => {
  return gql`
    ${figureMetadata}
    ${chartFragment}
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            ...figureMetadata
            panel {
                panel
                xAxis
                xAxis1
                xAxis2
                values {
                    ...highchartsConfig
                }
            }
        }
    }`;
};

export const getToggleFigureQuery = (
  figureNumber,
  chapterId,
  chartFragment
) => {
  return gql`
        ${figureMetadata}
        ${chartFragment}
        {
            figure(id: "${figureNumber}", chapterId: ${chapterId}) {
                ...figureMetadata
                toggle {
                    toggle
                    values {
                        ...highchartsConfig
                    }
                }
            }
        }
    `;
};

export const getTabToggleFigureQuery = (
  figureNumber,
  chapterId,
  chartFragment
) => {
  return gql`
        ${figureMetadata}
        ${chartFragment}
        {
            figure(id: "${figureNumber}", chapterId: ${chapterId}) {
                ...figureMetadata
                tab {
                    tab
                    values {
                        toggle
                        values {
                            ...highchartsConfig
                        }
                    }
                }
            }
        }
    `;
};

export const getTableQuery = (figureNumber, chapterId) => {
  return gql`
    {
        figure(id: "${figureNumber}", chapterId: ${chapterId}) {
            figureNumber
            figureJson
            toggleType
            chartType
            chapterNumber
            volume
            title
            shortTitle
            subTitle
            summary
            methods
            }
        }
    `;
};

import React from "react";

import config from "../../../config/config";

import "./ReferenceTables.scss";

const ReferenceTables2020 = () => {
  const download = (refNum) => {
    const url = `${config.baseUri}/referenceTables?year=2020&referenceTable=${refNum}`;
    window.location = url;
  };

  return (
    <>
      <h1>Reference Tables</h1>
      <section className="ref-tables-section">
        <ul>
          <li>
            <span className="ref-tables-volume">Volume 1 - CKD:</span>
            <ul>
              <li>
                <button
                  href="#"
                  onClick={() => download("CKD_Ref_B_Prevalence_2020")}
                >
                  B. Prevalence
                </button>
              </li>
              <li>
                <button
                  href="#"
                  onClick={() => download("CKD_Ref_K_Expenditures_2020")}
                >
                  K. Expenditures
                </button>
              </li>
            </ul>
          </li>
          <li>
            <span className="ref-tables-volume">Volume 2 - ESRD:</span>
            <ul>
              <li>
                <button onClick={() => download("ESRD_Ref_A_Incidence_2020")}>
                  A. Incidence
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_B_Prevalence_2020")}>
                  B. Prevalence
                </button>
              </li>
              <li>
                <button
                  onClick={() => download("ESRD_Ref_C_PatientChars_2020")}
                >
                  C. Patient Characteristics
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_D_Modality_2020")}>
                  D. Treatment Modalities
                </button>
              </li>
              <li>
                <button
                  onClick={() => download("ESRD_Ref_E_TransplantProcess_2020")}
                >
                  E. Transplantation: Process
                </button>
              </li>
              <li>
                <button
                  onClick={() => download("ESRD_Ref_F_TransplantOutcomes_2020")}
                >
                  F: Transplantation: Outcomes
                </button>
              </li>
              <li>
                <button
                  onClick={() => download("ESRD_Ref_G_Hospitalization_2020")}
                >
                  G. Morbidity and Hospitalization
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_H_Mortality_2020")}>
                  H. Mortality and Causes of Death
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_I_Survival_2020")}>
                  I. Patient Survival
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_J_Providers_2020")}>
                  J. Provider Characteristics
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_K_Costs_2020")}>
                  K. Expenditures for ESRD Patients
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_L_Vascacc_2020")}>
                  L. Vascular Access
                </button>
              </li>
              <li>
                <button onClick={() => download("ESRD_Ref_M_Census_2020")}>
                  M. Census Populations
                </button>
              </li>
              <li>
                <button
                  onClick={() => download("ESRD_Ref_N_International_2020")}
                >
                  N. International Comparisons
                </button>
              </li>
              <li>
                <button onClick={() => download("Reference Table Methods")}>
                  Analytical Methods
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </section>
    </>
  );
};

export default ReferenceTables2020;

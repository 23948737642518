export const extractMapSeries = (data, tab, toggle, dataPointMapper) => {
  let seriesData = [];
  let name = "";
  if (data.panel) {
    name = data.panel.panel;
    seriesData = data.panel.values.series.map(dataPointMapper);
  } else if (data.none) {
    name = data.none.panel;
    seriesData = data.none.values.series.map(dataPointMapper);
  } else if (data.tabOnly) {
    name = data.tabOnly.tab;
    seriesData = data.tabOnly
      .find((x) => x.tab === tab)
      .values.series.map(dataPointMapper);
  } else if (data.toggle) {
    name = toggle;
    seriesData = data.toggle
      .find((x) => x.toggle === toggle)
      .values.series.map(dataPointMapper);
  } else {
    name = tab;
    try {
      let tabSeriesData = data.tab
        .filter((x) => x.tab === tab)[0]
        .values.filter((y) => y.toggle === toggle)[0].values;
      if (!tabSeriesData.series) {
        seriesData = tabSeriesData.map(dataPointMapper);
      } else {
        seriesData = tabSeriesData.series.map(dataPointMapper);
      }
    } catch (ex) {
      console.log(ex);
      seriesData = [];
    }
  }

  name = name ?? data.figureNumber;

  const series = {
    type: "map",
    name,
    data: seriesData,
  };

  return series;
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import TogglePane from "./graphComponents/TogglePane";
import Header from "./graphComponents/Header";
import PanelInstance from "./PanelInstance";
import PanelMethods from "./graphComponents/PanelMethods";
import Summary from "./graphComponents/Summary";
import { DATA_SET_CHANGE } from "../../../../redux/actions/graphs";

const TogglePanel = ({ serverdata, slug, size }) => {
  const dispatch = useDispatch();

  const data = serverdata;

  const graphState = useSelector(
    (state) => state.graphState[data.figureNumber]
  );
  const toggleValues = Array.from(
    new Set(data.togglePanel.flatMap((x) => x.toggle))
  );
  const cToggle =
    graphState && graphState.toggle ? graphState.toggle : toggleValues[0];

  const [currentToggle, setToggle] = useState(cToggle);

  const initialPanel =
    data && data.togglePanel
      ? data.togglePanel.filter((x) => x.toggle === currentToggle)[0]
      : null;

  const [initPanel, setInitPanel] = useState(initialPanel);

  const toggleDataSet = (newToggle) => {
    setToggle(newToggle);

    setInitPanel(data.togglePanel.filter((x) => x.toggle === newToggle)[0]);

    dispatch({
      type: DATA_SET_CHANGE,
      payload: {
        figureNumber: data.figureNumber,
        toggle: newToggle,
      },
    });
  };

  const panelLayout = (panels) => {
    let i = 0;
    const divs = [];
    while (i < panels.length) {
      if (i % 2 === 0) {
        if (i + 1 < panels.length) {
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                toggle={currentToggle}
              />
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i + 1], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                toggle={currentToggle}
              />
            </div>
          );
        } else {
          divs.push(
            <div className="subpanels" key={i}>
              <PanelInstance
                panel={{
                  ...data,
                  panel: { ...panels[i], panelCount: panels.length },
                }}
                size={size}
                slug={slug}
                toggle={currentToggle}
              />
            </div>
          );
        }
      }
      i++;
    }
    return divs;
  };

  return (
    <>
      {size !== "micro" ? (
        <>
          <Header figureData={data} slug={slug} currentToggle={currentToggle} />
          <TogglePane
            figureData={data}
            toggleValues={toggleValues}
            size={size}
            toggleDataSet={toggleDataSet}
            toggle={currentToggle}
          />
        </>
      ) : (
        <></>
      )}
      <div className="panel-layout">
        <div className="panel-child">
          {initPanel && initPanel.values ? (
            panelLayout(initialPanel.values).map((x) => x)
          ) : (
            <></>
          )}
        </div>
      </div>
      <PanelMethods methods={data.methods} />
      <Summary figureData={{ ...data, panel: null }} size={size} />
    </>
  );
};

export default TogglePanel;

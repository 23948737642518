import React, { useEffect } from "react";
import { useParams } from "react-router";
import './GoogleSearch.scss';


function GoogleSearch() {

    const { year } = useParams();

    useEffect(() => {
        const script = document.createElement("script");
        document.head.append(script);
        script.src = "https://cse.google.com/cse.js?cx=b58936a8b5a10417f";
    }, []);

    return (
            <div className="gcse-search" data-defaultToRefinement={year}></div>
    );
}

export { GoogleSearch };
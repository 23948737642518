import {
  toolTipFormatter,
  yAxisFormatter,
} from "../../../../../utils/numberFormatters";
import { nonNullMerge } from "./customMerge";
import { enable100PercentageSharedToolTip } from "./figureSpecificOptions";
import mixinCommands from "./mixinCommands";

const toggleMixins = (initialOptions, data, json, { tab, toggle, panel }) => {
  if (json.TOGGLE) {
    if (json.TOGGLE[toggle]) {
      initialOptions = nonNullMerge(initialOptions, json.TOGGLE[toggle]);
      initialOptions = mixinCommands(initialOptions, json.TOGGLE[toggle], data);

      if (json.TOGGLE[toggle].YAXISFORMATTER) {
        initialOptions.yAxis.labels.formatter = yAxisFormatter(
          json.TOGGLE[toggle].YAXISFORMATTER.decimalPlaces
        );
      }

      if (json.TOGGLE[toggle].TOOLTIPFORMATTER) {
        initialOptions.tooltip.formatter = toolTipFormatter(
          json.TOGGLE[toggle].TOOLTIPFORMATTER.decimalPlaces,
          data.chartType
        );
      }

      if (json.TOGGLE[toggle].ENABLESHAREDTOOLTIP) {
        initialOptions = enable100PercentageSharedToolTip(
          initialOptions,
          json.TOGGLE[toggle].ENABLESHAREDTOOLTIP.decimalPlaces ??
            data.dataLabelDecimalPlaces,
          json.TOGGLE[toggle].ENABLESHAREDTOOLTIP.reversed
        );
      }

      initialOptions = mixinCommands(initialOptions, data, json.TOGGLE[toggle]);
    } else if (json.TOGGLE.default) {
      initialOptions = nonNullMerge(initialOptions, json.TOGGLE.default);
      initialOptions = mixinCommands(initialOptions, json.TOGGLE.default, data);

      if (json.TOGGLE.default.YAXISFORMATTER) {
        initialOptions.yAxis.labels.formatter = yAxisFormatter(
          json.TOGGLE.default.YAXISFORMATTER.decimalPlaces
        );
      }

      if (json.TOGGLE.default.TOOLTIPFORMATTER) {
        initialOptions.tooltip.formatter = toolTipFormatter(
          json.TOGGLE.default.TOOLTIPFORMATTER.decimalPlaces,
          data.chartType
        );
      }

      if (json.TOGGLE.default.ENABLESHAREDTOOLTIP) {
        initialOptions = enable100PercentageSharedToolTip(
          initialOptions,
          json.TOGGLE.default.ENABLESHAREDTOOLTIP.decimalPlaces ??
            data.dataLabelDecimalPlaces,
          json.TOGGLE.default.ENABLESHAREDTOOLTIP.reversed
        );
      }

      initialOptions = mixinCommands(initialOptions, data, json.TOGGLE.default);
    }
  }
  return initialOptions;
};

export default toggleMixins;

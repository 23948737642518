import React from "react";
import { useParams } from "react-router";

import config from "../../../config/config";
import "./AnalyticalMethods.scss";

const ESRDAnalyticalMethods2024 = () => {
  const { year } = useParams();

  const download = (refNum) => {
    const url = `${config.baseUri}/analyticalMethods?year=${year}&fileName=${refNum}&volumeNumber=2`;
    window.location = url;
  };

  if (year === "2024" && process.env.REACT_APP_BUILD === "production")
  {
    return ("This chapter is currently not available")
  }

  return (
    <div>
      <h1>Analytical Methods</h1>
      <ul>
        <li>
          <button onClick={() => download("Volume 2 Methods")}>
            Data Sources and Methods for ESRD Volume
          </button>
        </li>
        <li
          onClick={() =>
            download("Codes_for_Cause_of_Hospitalization_-_ESRD_Volume_2023")
          }
        >
          <button>Codes for Cause of Hospitalization - ESRD Volume</button>
        </li>
        <li onClick={() => download("Cause_of_Death_-_ESRD_Volume_2023")}>
          <button>Cause of Death – ESRD Volume</button>
        </li>
        <li
          onClick={() => download("Codes_and_Algorithm_for_Common_CVDs_2023")}
        >
          <button>Codes and Algorithm for CVD and Procedure</button>
        </li>
      </ul>
    </div>
  );
};

export default ESRDAnalyticalMethods2024;

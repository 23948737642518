/* eslint-disable no-template-curly-in-string */
import { get, set } from "lodash";
import merge from "lodash/merge";
import { RESPONSIVE_BREAK } from "../../../../../utils/constants";
import {
  toolTipFormatter,
  yAxisFormatter,
} from "../../../../../utils/numberFormatters";
import { replaceHdr } from "../graphComponents/Header";
import { nonNullMerge } from "./customMerge";
import {
  fullStackLabelsOnlyFormatter,
  rotateSecondXAxis,
  setAreaChartOptions,
  xAxisLabelsFormatter,
} from "./figureSpecificOptions";
import mixinCommands from "./mixinCommands";
import panelMixins from "./panelMixins";
import tabToggleMixins from "./tabToggleMixins";
import toggleMixins from "./toggleMixins";

const mixins = (initialOptions, data, { tab, toggle, panel }) => {
  try {
    let subtitle = "";
    let exportSubtitle = "";
    if (data.tab) {
      const selectedTab = data.tab.find((tb) => tb.tab === tab);
      if (selectedTab?.values) {
        const selectedToggle = selectedTab.values.find(
          (tog) => tog.toggle === toggle
        );
        initialOptions = nonNullMerge(initialOptions, selectedToggle.values);
      }
    }

    if (data.toggle) {
      const selectedToggle = data.toggle.find((tg) => tg.toggle === toggle);
      initialOptions = nonNullMerge(initialOptions, selectedToggle.values);
    }

    if (data.tabOnly) {
      const selectedTab = data.tabOnly.find((tb) => tb.tab === tab);

      if (selectedTab) {
        initialOptions = nonNullMerge(initialOptions, {
          ...selectedTab.values,
        });
      }
    }

    if (data.none?.values) {
      initialOptions = nonNullMerge(initialOptions, data.none.values);
    }

    if (data.tabPanel) {
      const selectedTab = data.tabPanel.find((tb) => tb.tab === tab);
      if (selectedTab?.values) {
        const selectedPanel = selectedTab.values.find(
          (pan) => pan.panel === panel
        );
        initialOptions = nonNullMerge(initialOptions, selectedPanel.values);
      }
    }

    if (data.panel?.values) {
      initialOptions = nonNullMerge(initialOptions, data.panel.values);
      if (data.panel.subtitle) {
        subtitle = data.panel.subtitle;
        exportSubtitle = data.panel.subtitle;
      }
    }

    initialOptions = mergeFigureJson(initialOptions, data, {
      tab,
      toggle,
      panel,
    });

    if (subtitle) {
      initialOptions.title.text = subtitle;
    }

    if (exportSubtitle) {
      initialOptions.exporting.chartOptions.subtitle.text = exportSubtitle;
    }

    replacePaths(initialOptions, { tab, toggle, panel });
    return initialOptions;
  } catch (e) {
    console.log(e);
    // TODO: temporary will remove later
    throw e;
  }
};

export const mergeFigureJson = (
  initialOptions,
  data,
  { tab, toggle, panel }
) => {
  if (data.figureJson) {
    const figJson = JSON.parse(data.figureJson);

    initialOptions = merge(initialOptions, figJson);

    initialOptions = tabToggleMixins(initialOptions, data, figJson, {
      tab,
      toggle,
      panel,
    });
    initialOptions = toggleMixins(initialOptions, data, figJson, {
      tab,
      toggle,
      panel,
    });
    initialOptions = panelMixins(initialOptions, data, figJson, {
      tab,
      toggle,
      panel,
    });

    initialOptions = mixinCommands(initialOptions, data, figJson);

    if (figJson.FULLSTACKLABELSONLYFORMATTER) {
      initialOptions.yAxis.stackLabels.formatter = fullStackLabelsOnlyFormatter;
    }

    if (figJson.XAXISLABELSFORMATTER) {
      initialOptions.xAxis.labels.formatter = xAxisLabelsFormatter;
    }

    if (figJson.YAXISFORMATTER) {
      initialOptions.yAxis.labels.formatter = yAxisFormatter(
        figJson.YAXISFORMATTER.decimalPlaces
      );
    }

    if (figJson.TOOLTIPFORMATTER) {
      if (figJson.TOOLTIPFORMATTER.scatterString) {
        initialOptions.tooltip.formatter = function () {
          return `<b>${this.key}:</b> ${this.x}, ${this.y}`;
        };
      } else {
        initialOptions.tooltip.formatter = toolTipFormatter(
          figJson.TOOLTIPFORMATTER.decimalPlaces,
          data.chartType
        );
      }
    }

    if (figJson.SETAREACHARTOPTIONS) {
      initialOptions = setAreaChartOptions(
        initialOptions,
        data,
        figJson.SETAREACHARTOPTIONS.reverse
      );
    }

    rotateSecondXAxisWrapper(initialOptions, figJson);

    if (figJson.REPLACEHDR) {
      let value = replaceHdr(
        get(data, figJson.REPLACEHDR.textPath),
        tab,
        toggle
      );
      if (figJson.REPLACEHDR.prefix) {
        value = figJson.REPLACEHDR.prefix + value;
      }
      set(initialOptions, figJson.REPLACEHDR.path, value);
    }

    if (window.innerWidth < RESPONSIVE_BREAK && figJson.MOBILE) {
      initialOptions = nonNullMerge(initialOptions, figJson.MOBILE);

      initialOptions = tabToggleMixins(initialOptions, data, figJson.MOBILE, {
        tab,
        toggle,
        panel,
      });
      rotateSecondXAxisWrapper(initialOptions, figJson.MOBILE);
    }
  }
  return initialOptions;
};

const rotateSecondXAxisWrapper = (initialOptions, figJson) => {
  if (figJson.ROTATESECONDXAXIS) {
    initialOptions = rotateSecondXAxis(
      initialOptions,
      figJson.ROTATESECONDXAXIS.useResponsiveBreak,
      figJson.ROTATESECONDXAXIS.rotateSingleCategory
    );
  }
  return initialOptions;
};

const replacePaths = (initialOptions, { tab, toggle, panel }) => {
  replaceText(initialOptions, "title.text", { tab, toggle, panel });
  replaceText(initialOptions, "xAxis.title.text", { tab, toggle, panel });
  replaceText(initialOptions, "yAxis.title.text", { tab, toggle, panel });
  replaceText(initialOptions, "exporting.chartOptions.subtitle.text", {
    tab,
    toggle,
    panel,
  });
};

const replaceText = (initialOptions, path, { tab, toggle, panel }) => {
  const tokenValue = get(initialOptions, path);
  if (tokenValue) {
    const replacedValue = tokenValue
      .replace("${initTab}", tab)
      .replace("${initialToggle}", toggle);
    set(initialOptions, path, replacedValue);
  }
};

export default mixins;

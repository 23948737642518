import React from 'react';

import './Footer.scss';

const Footer = () => {
    return (
        <footer>
            <div>
                <div className="footerborder"/>
                <div className="footerborder"/>

                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.niddk.nih.gov/privacy-policy">
                    Privacy Policy
                </a>
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.niddk.nih.gov/freedom-information-act">
                    Freedom of Information Act
                </a>
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.niddk.nih.gov/accessibility">
                    Accessibility
                </a>
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.niddk.nih.gov/disclaimers">
                    Disclaimers
                </a>
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.niddk.nih.gov/copyright">
                    Copyright
                </a>   
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"> 
                    Vulnerability Disclosure Policy
                </a> 
                <div className="footerborder"/>

                <br/>
                <br/>
                <div className="footerborder"/>
                <div className="footerborder"/>

                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.hhs.gov/">
                    U.S. Department of Health and Human Services
                </a>
                <span className="footerspan"> | </span>
                <div className="footerborder"/>
                <a className="footerlink" target="_blank" rel="noreferrer" href="https://www.nih.gov/">
                    National Institutes of Health
                </a>
                <div className="footerborder"/>
                
            </div>
        </footer>
    )
}

export default Footer
import React from 'react'
import DOMPurify from 'dompurify';

import './PanelMethods.scss';

const PanelMethods = ({ methods }) => {
    return (
        <div className="panel-methods" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(methods, { ADD_ATTR: ['target'] }) }}>
        </div>
    )
}

export default PanelMethods
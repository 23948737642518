const highchartsVennFix =
    (Highcharts) => {
        (function (H) {
            var venn = H.seriesTypes.venn.prototype;
            venn.drawPoints = function () {
                var series = this,
                    // Series properties
                    chart = series.chart, group = series.group, points = series.points || [],
                    // Chart properties
                    renderer = chart.renderer;
                // Iterate all points and calculate and draw their graphics.
                points.forEach(function (point) {
                    var attribs = {
                        zIndex: point.sets && point.sets.length ? point.sets.length : 0
                    }, shapeArgs = point.shapeArgs;
                    // Add point attribs
                    if (!chart.styledMode) {
                        H.extend(attribs, series.pointAttribs(point, point.state));
                    }
                    // Draw the point graphic.
                    point.draw({
                        isNew: !point.graphic,
                        animatableAttribs: shapeArgs ?? {},
                        attribs: attribs,
                        group: group,
                        renderer: renderer,
                        shapeType: shapeArgs && shapeArgs.d ? 'path' : 'circle'
                    });
                });
            }
        })(Highcharts)
    }

export default highchartsVennFix

import React from 'react'
import NIDDKLogo from './NIDDK_USRDS_logo.svg'
import MobileNav from './MobileNav'
import MobileChapter from './MobileChapter'
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import './Logo.scss';

const Logo = () => {
    return (
        <div>
            <div className="container">
                <div className="header">
                    <div className="top-wrapper">
                        <div className="main-logo">
                            <a href="#main" id="skip-link">Skip to Main Content</a>
                            <a href="/">
                                <div className="logo-wrapper">
                                    <img src={NIDDKLogo} className="main-logo-img" alt="USRDS ADR Home Page" />
                                </div>
                            </a>
                        </div>
                        <Navbar collapseOnSelect expand="md" className="desktop-nav">
                            <Container className="nobg">
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                                <div className="navbar-collapse collapse" id="responsive-navbar-nav">
                                    <div className="mr-auto navbar-nav">
                                        <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds" className="nav-link">USRDS <br/> Home</a>
                                        <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/annual-data-report" className="nav-link">Annual Data <br/> Report</a>
                                        <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/prior-data-reports" className="nav-link">Prior Data <br/> Reports</a>
                                        <div className="dropdown nav-item">
                                            <a aria-haspopup="true" aria-expanded="false" id="collasible-nav-dropdown" href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers" className="dropdown-toggle nav-link" role="button">For <br/> Researchers</a>
                                            <div className="dropdown-menu" aria-labelledby="collasible-nav-dropdown">
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/simple-data-requests" className="dropdown-item"> Simple Data Requests </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/standard-analysis-files" className="dropdown-item"> Standard Analysis Files </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/merged-data-requests" className="dropdown-item"> Merged Data Requests </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/for-researchers/researchers-guide" className="dropdown-item"> Researcher's Guide </a>
                                            </div>
                                        </div>
                                        <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/esrd-quarterly-update" className="nav-link">ESRD Quarterly <br/> Update</a>
                                        <div className="dropdown nav-item">
                                            <a aria-haspopup="true" aria-expanded="false" id="collasible-nav-dropdown" href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools" className="dropdown-toggle nav-link" role="button">Data Query <br/> Tools</a>
                                            <div className="dropdown-menu" aria-labelledby="collasible-nav-dropdown">
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-incident-count" className="dropdown-item"> ESRD Incident Count </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-prevalent-count" className="dropdown-item"> ESRD Prevalent Count </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-mortality-rate" className="dropdown-item"> ESRD Mortality Rate </a>
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/data-query-tools/esrd-hospitalization-rate" className="dropdown-item"> ESRD Hospitalization Rate </a>
                                            </div>
                                        </div>
                                        <div className="dropdown nav-item">
                                            <a aria-haspopup="true" aria-expanded="false" id="collasible-nav-dropdown" href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/news" className="dropdown-toggle nav-link" role="button">USRDS <br/> News</a>
                                            <div className="dropdown-menu" aria-labelledby="collasible-nav-dropdown">
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/news/publication-spotlights" className="dropdown-item"> Publication Spotlights </a>
                                            </div>
                                        </div>
                                        <div className="dropdown nav-item">
                                            <a aria-haspopup="true" aria-expanded="false" id="collasible-nav-dropdown" href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/about-usrds" className="dropdown-toggle nav-link" role="button">About <br/> USRDS</a>
                                            <div className="dropdown-menu" aria-labelledby="collasible-nav-dropdown">
                                                <a href="https://www.niddk.nih.gov/about-niddk/strategic-plans-reports/usrds/about-usrds/publications" className="dropdown-item"> Publications </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </Navbar>
                    </div>
                    <div className="bottom-wrapper">

                    </div>
                </div>
                <div className="mobile-logo">
                    {/* <a href="#main" id="skip-link">Skip to Main Content</a>
                <a href="/">
                    <img src={MobileLogo} className="mobile-logo-img" alt="Home Page"/>
                </a> */}
                    <MobileNav />
                </div>
            </div>
            <MobileChapter />
        </div>
    )
}

export default Logo
const stackedColumnOptions = (initialOptions, stackLabelFormat) => {
    return {
        ...initialOptions,
        yAxis: {
            ...initialOptions.yAxis,
            stackLabels: {
                enabled: true,
                format: mapLabelDecimalPlace[stackLabelFormat]
            },
            reversedStacks: false
        },
        plotOptions: {
            ...initialOptions.plotOptions,
            column: {
                stacking: 'normal'
            }
        }
    }
}

export const mapLabelDecimalPlace = {
    // eslint-disable-next-line
    ['10']: '{total:0.0f}',
    // eslint-disable-next-line
    ['1']: '{total:.0f}',
    // eslint-disable-next-line
    ['0.1']: '{total:.1f}',
    // eslint-disable-next-line
    ['0.01']: '{total:.01f}'
}

export default stackedColumnOptions
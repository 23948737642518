export const isContentChapter = (chapter, volume) => {
  if (chapter.chapterNumber < 1) {
    return false;
  } else if (
    chapter.title === "Analytical Methods"
  ) {
    return false;
  }
  return true;
};

export const getChapterPrefix = (chapter, volume) => {
  const chapPrefix = `Ch ${chapter.chapterNumber}: `;
  if (chapter.title === "Analytical Methods"){
    return ``;
  }
  return chapPrefix;
};

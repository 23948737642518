import hiddenChaptersDev from '../hiddenChapters.development.json';
import hiddenChaptersTest from '../hiddenChapters.test.json';
import hiddenChaptersProd from '../hiddenChapters.production.json';
import getYear from "../utils/getYear";

export const isHiddenChapter = (chapter, volume) => {

  let env = process.env.REACT_APP_BUILD;
  let hiddenChapters = env === 'development' ? hiddenChaptersDev :
                        env === 'demo' || env === 'staging' ? hiddenChaptersTest :
                        hiddenChaptersProd;

  let chapterList = hiddenChapters.find(x => x.year === parseInt(getYear()));
  return chapterList?.chapters.find(y => y.volume === volume?.order && y.chapter === chapter?.chapterNumber);

};

export const hiddenVolumeStyle = (volume) => {

  return getYear() === '2024' && process.env.REACT_APP_BUILD === 'production' && 
                        (volume.title === 'Healthy People 2030' || volume.title === 'Supplements: Healthcare Disparities')  ? 
                        'disabledChapter' : '';
}

export default isHiddenChapter

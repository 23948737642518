import React from "react";
import { useQuery } from "@apollo/client";
import { getFigureQuery } from "../../../../queries/figureData";
import Toggle from "./Toggle";
import Panel from "./Panel";
import TabToggle from "./TabToggle";
import TabAndPanel from "./TabAndPanel";
import TabTogglePanel from "./TabTogglePanel";
import TabOnly from "./TabOnly";
import Table from "./Table";
import TogglePanel from "./TogglePanel";
import Image from "./Image";

const Figure = ({
  figureNumber,
  chapterId,
  figureType,
  size,
  slug,
  chartType,
}) => {
  const figQuery = getFigureQuery(figureNumber, chapterId, figureType);
  const { data } = useQuery(figQuery);

  if (data && data.figure) {
    switch (figureType) {
      case "TOGGLE only":
        return <Toggle serverdata={data.figure} size={size} slug={slug} />;
      case "TOGGLE ONLY":
        return <Toggle serverdata={data.figure} size={size} slug={slug} />;
      case "TOGGLE AND PANEL":
        return <TogglePanel serverdata={data.figure} size={size} slug={slug} />;
      case "TAB AND TOGGLE":
        return <TabToggle serverdata={data.figure} size={size} slug={slug} />;
      case "TAB AND PANEL":
        return <TabAndPanel serverdata={data.figure} size={size} slug={slug} />;
      case "TAB AND TOGGLE AND PANEL":
        return (
          <TabTogglePanel serverdata={data.figure} size={size} slug={slug} />
        );
      case "PANEL ONLY":
        return <Panel serverdata={data.figure} size={size} slug={slug} />;
      case "TAB ONLY":
        return <TabOnly serverdata={data.figure} size={size} slug={slug} />;
      case "NONE":
        return <Panel serverdata={data.figure} size={size} slug={slug} />;
      case "TABLE":
        return <Table serverdata={data.figure} size={size} slug={slug} />;
      case "IMAGE":
        return <Image serverdata={data.figure} size={size} slug={slug} />; 
      default:
        throw new Error("Could not determine figure type");
    }
  } else {
    return <></>;
  }
};

export default Figure;

import React from "react";
import { useParams } from "react-router";

import config from "../../../config/config";
import "./AnalyticalMethods.scss";

const ESRDAnalyticalMethods2022 = () => {
  const { year } = useParams();

  const download = (refNum) => {
    const url = `${config.baseUri}/analyticalMethods?year=${year}&fileName=${refNum}&volumeNumber=2`;
    window.location = url;
  };

  return (
    <div>
      <h1>Analytical Methods</h1>
      <ul>
        <li>
          <button onClick={() => download("Volume 2 Methods")}>
            Data Sources and Methods for ESRD Volume
          </button>
        </li>
        <li
          onClick={() =>
            download("Codes for Cause of Hospitalization - ESRD Volume")
          }
        >
          <button>Codes for Cause of Hospitalization - ESRD Volume</button>
        </li>
        <li onClick={() => download("Cause of Death - ESRD Volume")}>
          <button>Cause of Death – ESRD Volume</button>
        </li>
        <li
          onClick={() => download("Codes and Algorithm for CVD and Procedure")}
        >
          <button>Codes and Algorithm for CVD and Procedure</button>
        </li>
      </ul>
    </div>
  );
};

export default ESRDAnalyticalMethods2022;

// eslint-disable-next-line import/no-anonymous-default-export
export default (figId, data, tab) => {
  const nones = document.getElementsByClassName("a-none-circle-" + figId);
  if (nones.length) {
    for (let i = 0; i < nones.length; i++) {
      nones[i].remove();
    }
  }

  // delete old none labels when switching tabs
  if (data.toggleType === "TAB AND PANEL") {
    const unSelectedTabPanels = data.tabPanel
      .filter((x) => {
        return tab !== x.tab;
      })
      .flatMap((y) => y.values);
    const unSelectedTabPanelIds = unSelectedTabPanels.map(
      (x) => data.figureNumber.replace(/\W/g, "") + x.panel.replace(/\W/g, "")
    );
    removeLabels(unSelectedTabPanelIds);
  } else if (data.toggleType === "TAB AND TOGGLE") {
    const unselectedTabs = data.tab
      .filter((x) => {
        return tab !== x.tab;
      })
      .flatMap((y) => y.values);
    const unselecteTabIds = unselectedTabs.map((x) =>
      data.figureNumber.replace(/\W/g, "")
    );
    removeLabels(unselecteTabIds);
  }
};

const removeLabels = (labels) => {
  for (let i = 0; i < labels.length; i++) {
    const id = "a-none-circle-" + labels[i];
    const noneCircles = document.getElementsByClassName(id);
    for (let j = 0; j < noneCircles.length; j++) {
      noneCircles[j].remove();
    }
  }
};

import React from "react";
import { Table } from "react-bootstrap";
import config from "../../config/config";

import "./HealthyPeople.scss";

const HealthyPeople2020 = () => {
  const download = (file) => {
    const url = `${config.baseUri}/healthyPeople2020?hpTable=${file}`;
    window.location = url;
  };

  return (
    <>
      <h1>
        Healthy People 2020 CKD Objectives
        <button
          style={{ fontSize: ".5em", textDecoration: "underline" }}
          onClick={() => download("HP2020_20")}
        >
          Download All Objectives
        </button>
      </h1>
      <section>
        <Table responsive className="hp-table">
          <thead>
            <tr>
              <td>Objective</td>
              <td>Indicator</td>
              <td>Target</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CKD-1*</td>
              <td>
                Reduce the proportion of the U.S. population with chronic kidney
                disease
              </td>
              <td>13.30%</td>
            </tr>
            <tr>
              <td>CKD-2*</td>
              <td>
                Increase the proportion of persons with chronic kidney disease
                (CKD) who know they have impaired renal function
              </td>
              <td>13.40%</td>
            </tr>
            <tr>
              <td>CKD-3</td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T1")}>
                  Increase the proportion of hospital patients who incurred
                  acute kidney injury who have follow-up renal evaluation in 6
                  months post discharge
                </button>
              </td>
              <td>12.30%</td>
            </tr>
            <tr>
              <td>CKD-4</td>
              <td>
                Increase the proportion of persons with diabetes and chronic
                kidney disease who receive recommended medical evaluation
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T2")}>
                  4.1 Increase the proportion of persons with chronic kidney
                  disease who receive medical evaluation with serum creatinine,
                  lipids, and microalbuminuria
                </button>
              </td>
              <td>28.40%</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T3")}>
                  4.2 Increase the proportion of persons with type 1 or type 2
                  diabetes and chronic kidney disease who receive medical
                  evaluation with serum creatinine, microalbuminuria, HbA1c,
                  lipids, and eye examinations
                </button>
              </td>
              <td>25.30%</td>
            </tr>
            <tr>
              <td>CKD-5</td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T4")}>
                  Increase the proportion of persons with diabetes and chronic
                  kidney disease who receive recommended medical treatment with
                  angiotensin-converting enzyme (ACE) inhibitors or angiotensin
                  II receptor blockers (ARBs)
                </button>
              </td>
              <td>74.10%</td>
            </tr>
            <tr>
              <td>CKD-6*</td>
              <td>
                Improve cardiovascular care in persons with chronic kidney
                disease
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                6.1 Reduce the proportion of persons with chronic kidney disease
                who have elevated blood pressure
              </td>
              <td>17.60%</td>
            </tr>
            <tr>
              <td></td>
              <td>
                6.2 Increase the proportion of adults aged 50 years and over
                with chronic kidney disease who currently take statins to lower
                their blood cholesterol
              </td>
              <td>25.60%</td>
            </tr>
            <tr>
              <td>CKD-7*</td>
              <td>
                Reduce the number of deaths among persons with chronic kidney
                disease
              </td>
              <td>Not applicable</td>
            </tr>
            <tr>
              <td>CKD-8</td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T5")}>
                  Reduce the number of new cases of end-stage renal disease
                  (ESRD)
                </button>
              </td>
              <td>352.1 PMP</td>
            </tr>
            <tr>
              <td>CKD-9</td>
              <td>Reduce kidney failure due to diabetes</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T6")}>
                  9.1 Reduce kidney failure due to diabetes
                </button>
              </td>
              <td>154.4 PMP</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T7")}>
                  9.2 Reduce kidney failure due to diabetes among persons with
                  diabetes
                </button>
              </td>
              <td>2,352.7 PMP</td>
            </tr>
            <tr>
              <td>CKD-10</td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T8_F1")}>
                  Increase the proportion of chronic kidney disease patients
                  receiving care from a nephrologist at least 12 months before
                  the start of renal replacement therapy
                </button>
              </td>
              <td>30.00%</td>
            </tr>
            <tr>
              <td>CKD-11</td>
              <td>Improve vascular access for hemodialysis patients</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T9")}>
                  11.1 Increase the proportion of adult hemodialysis patients
                  who use arteriovenous fistulas as the primary mode of vascular
                  access
                </button>
              </td>
              <td>50.60%</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T10")}>
                  11.2 Reduce the proportion of adult hemodialysis patients who
                  use catheters as the only mode of vascular access
                </button>
              </td>
              <td>26.10%</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T11")}>
                  11.3 Increase the proportion of adult hemodialysis patients
                  who use arteriovenous fistulas or have a maturing fistula as
                  the primary mode of vascular access at the start of renal
                  replacement therapy
                </button>
              </td>
              <td>34.80%</td>
            </tr>
            <tr>
              <td>CKD-12</td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T12")}>
                  Increase the proportion of dialysis patients waitlisted and/or
                  receiving a deceased donor kidney transplant within 1 year of
                  end-stage renal disease (ESRD) start (among patients under 70
                  years of age)
                </button>
              </td>
              <td>18.70%</td>
            </tr>
            <tr>
              <td>CKD-13</td>
              <td>
                Increase the proportion of patients with treated chronic kidney
                failure who receive a transplant
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T13_F2")}>
                  13.1 Increase the proportion of patients receiving a kidney
                  transplant within 3 years of end-stage renal disease (ESRD)
                </button>
              </td>
              <td>20.10%</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T14")}>
                  13.2 Increase the proportion of patients who receive a
                  preemptive transplant at the start of ESRD
                </button>
              </td>
              <td>Not applicable</td>
            </tr>
            <tr>
              <td>CKD-14</td>
              <td>
                Reduce deaths in persons with end-stage renal disease (ESRD)
              </td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T15")}>
                  14.1 Reduce the total number of deaths for persons on dialysis
                </button>
              </td>
              <td>187.3 per 1,000 PY</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T16")}>
                  14.2 Reduce the number of deaths in dialysis patients within
                  the first 3 months of initiation of renal replacement therapy
                </button>
              </td>
              <td>335.0 per 1,000 PY</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T17")}>
                  14.3 Reduce the number of cardiovascular deaths for persons on
                  dialysis
                </button>
              </td>
              <td>81.3 per 1,000 PY</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T18")}>
                  14.4 Reduce the total number of deaths for persons with a
                  functioning kidney transplant
                </button>
              </td>
              <td>27.8 per 1,000 PY</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <button onClick={() => download("v3_c01_hp2020_20_T19")}>
                  14.5 Reduce the number of cardiovascular deaths in persons
                  with a functioning kidney transplant
                </button>
              </td>
              <td>4.5 per 1,000 PY</td>
            </tr>
          </tbody>
        </Table>
        <p>
          Data Source:{" "}
          <a
            href="https://www.healthypeople.gov/2020/topics-objectives/topic/chronic-kidney-disease/objectives"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.healthypeople.gov/2020/topics-objectives/topic/chronic-kidney-disease/objectives
          </a>
          .{" "}
        </p>
        <p>
          Abbreviations: CKD, chronic kidney disease; ESRD, end-stage renal
          disease; HbA1c, glycosylated hemoglobin; HP2020, Healthy People 2020;
          PMP, per million population; PY, patient-years
        </p>
        <p>
          * These objectives use a data source other than USRDS, and are
          therefore not reported in this chapter.
        </p>
      </section>
    </>
  );
};

export default HealthyPeople2020;
